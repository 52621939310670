import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, Paper } from "@mui/material";
import { Avatar, TextareaAutosize } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery, useTheme } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SendIcon from '@mui/icons-material/Send';
import config from "../scripts/config";
export default function CreatePost(props) {
    const theme = useTheme();
    const firstLetter = props.nickName ? props.nickName.charAt(0) : '';
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // const [file, setFile] = React.useState(null)
    const [description, setDescription] = useState('')
    const [file, setFile] = useState(null)
    const [selectedFile, setSelectedFile] = useState("Click here to Select Image")
    const fileInputRef = useRef(null);
    const [uploading, setUploading] = useState(false);
    const link = localStorage.getItem("avatar");
    const [error, setError] = useState(false);
    const [previewSrc, setPreviewSrc] = useState(null); // State to store the preview image URL

    const handleClick = () => {
        fileInputRef.current.click();
    };

    // Step 3: Create an event handler to update the state
    const handleTextChange = (event) => {
        // Update the 'text' state with the new value when the user types
        setDescription(event.target.value);
    };

    const [selectedCategory, setSelectedCategory] = useState("General");

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCategory(value);
    };

    const handleFileChange = (event) => {
        if (file) {
            setFile(null);
        }
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile)
            const displayFileName = selectedFile.name.length > 20 ? `...${selectedFile.name.slice(-20)}` : selectedFile.name;
            setSelectedFile(displayFileName);
            setError(false)

            // Create a URL for the selected file to display it as an image preview
            const fileURL = URL.createObjectURL(selectedFile);
            setPreviewSrc(fileURL);
        }
    };

    const handleUpload = async () => {

        if (!file) {
            setError(true);
            return;

        }
        setUploading(true)
        const formData = new FormData();
        formData.append('file', file);

        const uuid = props.userId; // Replace with the actual UUID
        const token = props.queryString// Replace with your bearer token
        const nickName = props.nickName;//user nickname
        formData.append('uuid', uuid);
        formData.append('description', description);
        formData.append('nickName', nickName);
        formData.append('tag', selectedCategory); // Convert tags array to comma-separated string

        const url = `${config.apiHost}/api/v1/posts/upload`;


        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                props.setNotificationMessage("New Memes Uploaded!!")
                // Update loadedPosts with unique posts after setting notifications
                props.setLoadedPosts(prevNotifications => [...prevNotifications, data]);
                setUploading(false)
                props.closeAction();
            } else {
                console.error('Upload failed with status:', response.text);
                setUploading(false);

            }
        } catch (error) {
            console.error('Error uploading file:', error);
            props.closeAction();
            alert("Failed to post");
        }
        // window.location.reload();
    }

    const ITEM_HEIGHT = 30;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                marginLeft: "5%"
            },
        },
    };

    function getStyles(name, selectedCategory, theme) {
        return {
            fontWeight:
                selectedCategory.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const paperRef = useRef(null);


    // This useEffect will trigger whenever 'yourProperty' changes
    useEffect(() => {
        if (paperRef.current) {
            paperRef.current.scrollTop = paperRef.current.scrollHeight;
        }
    }, [previewSrc]); // Add 'yourProperty' to the dependency array

    return (
        <div style={

            isSmallScreen ? {
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
                position: "fixed",
                height: "500px"
            } : {
                justifyContent: "center",
                alignItems: "center",
                width: "500px",
                height: "400px",
                position: "fixed",
                display: "flex",
                top: "35%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}>
            {/* main container that holds the app */}

            <Paper ref={paperRef} variant="outlined" style={{ backgroundColor: "#17181C", marginTop: "20%", width: "88%", height: "100%", marginLeft: "6%", display: "block", border: "2px solid #23252A", borderRadius: "15px" ,overflowY:"scroll" }}>
                <div style={{ marginTop: "5%", marginBottom: "5%", display: "flex" }}>
                    <Avatar src={link} style={{ marginLeft: "10%", marginRight: "60%" }} >
                        {firstLetter}
                    </Avatar>
                    <button style={{ height: "30px", backgroundColor: "transparent", border: "0px" }}><FontAwesomeIcon icon={faClose}
                        onClick={props.closeAction}
                        color="#45ADE4" size="2x" cursor="pointer" /></button>
                </div>
                <div>
                    {/* holds the avatar and the text area */}
                    <div id="avatarDiv" style={{ display: "flex", justifyContent: "center" }}>
                        {/* the input text area */}
                        <TextareaAutosize id="textArea" variant="outlined" style={{ resize: "none", marginTop: "1%", width: "80%", height: "150px", marginRight: "1%", border: "1px solid #23252A", backgroundColor: "transparent", textAlign: "center", borderRadius: "8px", fontSize: "20px", color: "white" }} placeholder="       Type your text here. (You can leave it blank)... Limited to 500 characters"
                            onChange={handleTextChange}
                            maxLength={500}  // Limit the number of characters
                        ></TextareaAutosize>
                    </div>
                    <div id="buttonDiv"
                        style={{
                            display: "block",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "2%", padding: "2px"
                        }}>
                        <div>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                multiple
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange} />
                            <p
                                onClick={handleClick}
                                style={{
                                    padding: "5px", color: error ? "red" : "#3C97C7",
                                    maxHeight: "20px", border: "1px dashed #3C97C7", textAlign: "center",
                                    width: "fit-content",
                                    borderRadius: "8px",
                                    cursor: "pointer"
                                }}>
                                {selectedFile}
                            </p>

                        </div>

                        <div style={{ display: "block" }}>
                            <Select
                                displayEmpty
                                value={selectedCategory}
                                onChange={handleChange}
                                input={<OutlinedInput style={{ color: "#3C97C7", border: "1px solid red" }} />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <em style={{ color: "white" }}>Choose Meme Category</em>;
                                    }

                                    return selected;
                                }}
                                style={{
                                    border: ".5px solid #3C97C7",
                                    height: "35px",
                                    color: "#3C97C7",
                                    maxWidth: isSmallScreen ? "250px" : "450px"
                                }}

                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}>

                                <MenuItem disabled value="">
                                    <em>Meme Category</em>
                                </MenuItem>
                                {props.categories.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, selectedCategory, theme)}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        {!uploading ? (

                            <button
                                onClick={handleUpload}
                                style={{
                                    color: "whitesmoke",
                                    fontSize: "16px",
                                    justifyContent: "space-between",
                                    textAlign: "center",
                                    alignItems: "center",
                                    border: "1px solid red",
                                    backgroundColor: "transparent",
                                    borderRadius: "4px",
                                    marginTop: "10px",
                                    width: "130px",
                                    height: "31px",
                                    cursor: "pointer",
                                    display: "flex", // Use flexbox
                                    flexDirection: "row", // Ensure horizontal layout
                                    gap: "5px", // Optional: add space between text and icon
                                }}
                            >
                                Upload
                                <SendIcon style={{ color: "red", fontSize: "20px" }} />

                            </button>
                        ) : (<div style={{ display: "flex" }}>
                            <p
                                onClick={handleClick}
                                style={{
                                    marginLeft: "15%",
                                    marginTop: "2%", color: "#3C97C7",
                                    maxHeight: "20px", border: "0px solid #3C97C7", textAlign: "center",
                                    width: "150px",
                                    borderRadius: "8px",
                                    cursor: "pointer"
                                }}>
                                Uploading ......
                            </p>
                            <CircularProgress color="inherit" style={{ marginTop: "1%", marginLeft: "10%" }} />
                        </div>
                        )}

                    </div>
                </div>
                {/* Image preview */}
                {previewSrc && (
                    <div style={{ textAlign: "center", marginBottom: "10px" , height:"200px",width:"100%",backgroundColor:"#17181C",borderRadius:"0 0 18px 18px", overflowY:"scroll"}}>
                        <img
                            src={previewSrc}
                            alt="Preview"
                            style={{ maxWidth: "320px", // Limits the width
                                width: "100%",  // Ensures the image scales proportionally within the maxWidth
                                height: "auto",  // Maintains the aspect ratio
                                 borderRadius: "10px" }}
                        />
                    </div>
                )}
            </Paper>
            {/* <br /> */}
        </div>
    )
}