import { Box, useMediaQuery, useTheme, Avatar, Typography, Fade } from "@mui/material";
import '../styles/Categories.css'
import React, { useRef, useEffect, useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import Post from "../Posts/Post";
import { Link } from "react-router-dom";
import OverlayMessage from "../Posts/OverlayUnsigned";
import config from "../scripts/config";


const NotSignedIn = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    // Check if the prompt has been shown before
    const promptShown = localStorage.getItem("signupPrompt");

    if (!promptShown) {
      // If not shown before, show the prompt
      setShowOverlay(true);
      // Set the localStorage item to indicate that the prompt has been shown
      localStorage.setItem("signupPrompt", "true");
    }

    // Optional: Hide the prompt after a certain time (e.g., 10 seconds)
    const timeout = setTimeout(() => {
      setShowOverlay(false);
    }, 10000);

    return () => clearTimeout(timeout); // Cleanup the timeout if the component unmounts
  }, []);

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };
  // enables us to autoload when the user reaches 
  function AutoLoadOnScroll() {
    const targetRef = useRef(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loadedPosts, setLoadedPosts] = useState([]);
    const [viewedPostIds, setViewedPostIds] = useState([]);
    const [views, setViews] = React.useState([]);
    let page = 0;
    let size = 10;

    const sendUpdate = async (type, list) => {
      const url = `${config.apiHost}/api/v1/posts/batchUpdate${type.charAt(0).toUpperCase() + type.slice(1)}?increment=1&userId=${localStorage.getItem("memerId")}`;
      try {
          const response = await fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem("oauth")}`
              },
              body: JSON.stringify(list) // Directly send the list of UUIDs
          });
          if (!response.ok) {
              throw new Error(`Server error: ${response.statusText} `);
          }
      } catch (error) {
          console.error(`Error updating ${type}:`, error);
      }
  };

    const batchUpdate = async () => {
      if (views.length > 0) {
          await sendUpdate('views', views);
          setViews([]);
      }
  };

    //data fetching function
    const fetchPosts = async () => {

      try {
        // Define the URL of the API endpoint you want to fetch data from
        // Define the URL of the API endpoint you want to fetch data from
        const apiUrl = `${config.apiHost}/api/v1/posts/getPostsForUnsigned?page=${page}&size=${size}`;

        // Define the headers you want to include in the request
        const headers = new Headers();
        headers.append('Accept', 'application/json');
        // Make an HTTP GET request using fetch and await the response
        const posts = await fetch(apiUrl, {
          method: 'GET', // Specify the HTTP method (GET in this case)
          headers: headers, // Include the headers in the request
        });

        // Check if the response status is OK (200)
        if (!posts.ok) {
          throw new Error('Network response was not ok');
        } else {
          // console.log("Response success", posts.status)
        }

        // Parse the response JSON data
        const apiData = await posts.json();

        if (apiData.empty) {
          page -= 1;
          // It's the last page, no need to fetch more data
          // console.log("Last page.");
          return;
        }
        // Update the state with the fetched data
        setLoadedPosts((prevLoadedPosts) => [
          ...prevLoadedPosts,
          ...apiData.content,
        ]);

      } catch (error) {
        console.log("Error fetching data ", error)
      }

    }

    const containerStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: "1200px",
      margin: "0 auto"
    };


    useEffect(() => {

      const options = {
        root: null, // viewport
        rootMargin: '0px',
        threshold: 0.2, // 0.1 means 10% of the element must be visible
      };

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // The target element is in the viewport
            fetchPosts();
            page += 1;

          }
        });
      };

      const observer = new IntersectionObserver(callback, options);

      if (targetRef.current) {
        observer.observe(targetRef.current);
      }

      return () => {
        if (targetRef.current) {
          observer.unobserve(targetRef.current);
        }
      };
    }, []);

    return (

      <div style={containerStyle}>
        {/* This is the target element */}
        {loadedPosts.length > 0 ? (
          loadedPosts.map((item, index) => (
            // ... Your mapping code here
            <div key={index} >
              <Post
                name={item.nickName}
                image={`https://www.memeskenya.com${item.imageUrl}`} // Replace this with the image data
                avatar={item.ownerDP}
                time={item.timePosted}
                likes={item.likeCount}
                tag={item.tag}
                downloads={item.downloads}
                views={item.views}
                notsignedIn={true}
                setViews={setViews}
                batchUpdate={batchUpdate}
                isVerified={item.verified}
                description={item.postTitle}
              />
            </div>
          ))
        ) : (
          // Render a loading message or something else when no data is available
          <div style={{ textAlign: "center", marginTop: "20%" }}>
            <p>Getting content. Please wait......</p>
          </div>
        )
        }

        <div ref={targetRef} style={{ width: isSmallScreen ? "85vw" : "500px", color: "white" }}>
          <Box sx={{ display: "flex" }}>
            <Skeleton variant="circular"
              sx={{ bgcolor: 'grey.900', margin: "5%" }} >
              <Avatar />
            </Skeleton>
            <Skeleton variant="rectangular"
              sx={{
                bgcolor: 'grey.900',
                height: "20px",
                marginTop: "8%", borderRadius: "6px"
              }} >
              <Typography sx={{ width: "180px" }}></Typography>
            </Skeleton>
          </Box>
          <Skeleton variant="rectangular"
            style={{ backgroundColor: '#141414', margin: "5%", marginTop: "1%", borderRadius: "6px", width: isSmallScreen ? "85vw" : "500px", height: "199px" }}
          >
          </Skeleton>
        </div>
      </div >
    );
  }


  return (
    <Fade in timeout={{ enter: 2000 }}>
      <div style={{ justifyContent: "center", maxWidth: "1200px" }}>
        <div style={{ position: "sticky", top: -10, width: "100%", backgroundColor: "black", zIndex: 1000, textAlign: "center", display: "flex", alignItems: "center" }}>
          <div style={{ padding: "5px" }}>
            <p style={{ textAlign: "center" }} id="title">Memes<span id="titleSpan">Kenya</span></p>
            <Link to="/log-in" delay={500} style={{ textDecoration: "none", color: "aliceblue" }}>
              <button style={{
                textDecoration: "none", backgroundColor: "#45ADE4", color: "white", width: "200px", height: "35px", fontSize: "15px", border: "1px solid #45ADE4", borderRadius: "6px",
                cursor: "pointer", textAlign: "center"
              }}>
                Sign In to MemesKenya
              </button>
            </Link>
          </div>
          {showOverlay && (<div style={{ backgroundColor: "transparent", marginTop: "3%", marginLeft: "2%" }}>
            <OverlayMessage message="Create an account (which of course is FREE) and access these features:" onClose={handleCloseOverlay} />
          </div>)}
        </div>
        <div>
          <AutoLoadOnScroll />
        </div>
      </div>
    </Fade>
  );
};

export default NotSignedIn;