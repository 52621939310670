import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Typography,
  Fade,
  CircularProgress,
} from '@material-ui/core';
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { Alert, AlertTitle, Backdrop, Button, Stack } from '@mui/material';
import ChatMsg from '@mui-treasury/components/chatMsg/ChatMsg';
import { Textarea } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleInfo, faPaperPlane, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import BaseUrl from '../scripts/BaseUrl';
import { useNavigate } from 'react-router-dom';

async function GetChats(messageBody, token) {
  const header = new Headers();
  header.append('Accept', 'application/json');
  header.append('Content-Type', 'application/json');
  header.append("Authorization", `Bearer ${token}`)
  // Define the URL you want to make a GET request to

  const url = BaseUrl() + '/api/v1/getMessagesBySenderAndReceiver' +
    `?sender=${encodeURIComponent(messageBody.sender)}
  &receiver=${encodeURIComponent(messageBody.receiver)}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });

    if (response.ok) {
      // The POST request(signUp) was successful, handle the response as needed
      const data = await response.json();
      return data;
    } else {
      // Handle error response here
      console.error('Message fetch failed', messageBody);
    }
  } catch (error) {
    // Handle network or other errors here
    console.error('Error:', error);
  }
}


const ChatView = () => {
  const navigate = useNavigate();
  const person = useSelector((state) => state.person);
  const [messages, setMessages] = useState([]);

  const [message, setMessage] = useState('');
  const [senderName, setSenderName] = useState('');
  const [senderId, setSenderId] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [stompClient, setStompClient] = useState(null);
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Bearer ${person.authToken}`);
  const socket = new SockJS('http://localhost:8082/ws');

   // Sort the usernames alphabetically
   const sortedUsernames = [person.currentMemeChat, person.name].sort();

  const client = Stomp.over(socket);

  useEffect(() => {
    setSenderId(person.memerId);
    setSenderName(person.name)
    setReceiverName(person.currentMemeChat);
    (async () => {
      const messageBody = {
        sender: person.memerId,
        receiver: person.currentMemeChatId,
      }
      const data = await GetChats(messageBody, person.authToken)
      setMessages(data);
    })();
  }, [])

  useEffect(() => {

    client.connect(headers, () => {
      client.subscribe(`/queue/${sortedUsernames[0]}_${sortedUsernames[1]}/reply`, (message) => {
        const receivedMessage = JSON.parse(message.body);
        // Add the message to the state
        setMessages((prevMessages) => [...prevMessages, receivedMessage]);
        console.log("messages ", receivedMessage)
      });
    });
    setStompClient(client);
    // Return a cleanup function to disconnect the WebSocket when the component unmounts
    return () => {
      if (stompClient) {
        stompClient.disconnect();
      }
    }
  }, []);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const sendMessage = () => {
    if (message.trim()) {
      const chatMessage = {
        senderName,
        senderId,
        receiverId: `${person.currentMemeChatId}`,
        receiverName,
        message: message,
      };
      stompClient.send(`/app/chat/private/${sortedUsernames[0]}_${sortedUsernames[1]}`, {}, JSON.stringify(chatMessage));
      setMessage('');
    }
  };
  //prevents the socket from sending duplicates
  let uniqueValues = messages.filter((msg, index, self) => {
    return index === self.findIndex((m) =>
      m.timestamp === msg.timestamp);
  });

  return (
    <>
      <Fade in timeout={1000}>
        <div style={{ padding: "10px", backgroundColor: "black" }}>
          <div style={{ width: "100vw", paddingTop: "5%", paddingBottom: "1.5%", display: "flex", position: 'fixed', top: 0, backgroundColor: "black", alignItems: "center", zIndex: 999 }}>
            {/* Go back to the messages window/view */}
            <Button style={{ backgroundColor: "transparent" }} onClick={() => {
              navigate("/messages")
            }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <Avatar alt={receiverName.charAt(1)} src="/static/images/avatar/1.jpg" />
            <Typography style={{ marginLeft: "3%" }}>{receiverName}</Typography>
            {/* chat info/options */}
            <FontAwesomeIcon icon={faCircleInfo} size='1x' style={{ marginLeft: "25%" }} />
          </div>
          <div style={{ marginTop: "20%", marginBottom: "25%" }}>
            {uniqueValues.map((data) => (
              <div style={{ color: "black", WebkitTextFillColor: senderName === data.senderName ? 'white' : 'black' }}>
                <ChatMsg
                  key={data.messageId}
                  side={senderId !== data.senderId ? 'left' : 'right'}
                  messages={[
                    data.message
                  ]}
                />
              </div>
            ))}
          </div>
          <div style={{
            display: "flex",
            width: "100vw",
            justifyContent: "center",
            padding: "1px",
            position: "fixed",
            bottom: -1,
            backgroundColor: "black",
            paddingBottom: "5%",
            paddingTop: "5%"
          }}>
            <Button style={{ marginRight: "0%" }}>
              <FontAwesomeIcon icon={faPlusCircle} size='2x' />
            </Button>
            {/* chat message box */}
            <Textarea minRows={1} onChange={handleMessageChange} sx={{ borderRadius: "18px" }} placeholder='Type message...' variant="solid" />
            {/* send button */}
            <Button onClick={sendMessage}
              // disabled={!message.trim()}
              style={{ marginRight: "10%" }}>
              <FontAwesomeIcon icon={faPaperPlane} size='2x' />
            </Button>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default ChatView;
