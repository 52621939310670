import React, { useState } from 'react';
import config from '../scripts/config';
import { TextField } from '@mui/material';

const SendMessage = () => {
  const [messageText, setMessageText] = useState('');
  const [title, setTitle] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const handleSendMessage = () => {
    if (messageText.trim() === '') {
      setResponseMessage('Message cannot be empty.');
      return;
    }
    if (title.trim() === '') {
      setResponseMessage('Title cannot be empty.');
      return;
    }

    fetch(`${config.apiHost}/api/v1/messages/sendMessage?title=${title}&message=${messageText}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("oauth")}`
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log('Message saved:', data);
        setResponseMessage('Message sent successfully!');
        setMessageText('');
        setTitle('')
      })
      .catch(error => {
        console.error('Error saving message:', error);
        setResponseMessage('Failed to send message. Please try again.');
      });
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Send a Message</h1>
      <TextField label="Title"
      value={title}
        onChange={e => setTitle(e.target.value)}
        style={{ border: "1px solid red", marginBottom: "5%", backgroundColor: "white", borderRadius: "10px" }} />

      <textarea
        style={styles.textarea}
        value={messageText}
        onChange={e => setMessageText(e.target.value)}
        placeholder="Type your message here..."
      />
      <button style={styles.button} onClick={handleSendMessage}>
        Send Message
      </button>
      {responseMessage && <p style={styles.response}>{responseMessage}</p>}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  textarea: {
    width: '100%',
    maxWidth: '400px',
    height: '100px',
    padding: '10px',
    fontSize: '16px',
    marginBottom: '20px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  response: {
    marginTop: '20px',
    fontSize: '18px',
    color: '#333',
  },
};

export default SendMessage;
