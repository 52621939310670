import React from 'react';
import './styles/index.css'
import LandingPage from './LandingPage/Landing';
import Login from './LoginPage/LogInPage';
import Overview from "./ProfileOverview/Overview"
import Home from "./Posts/Home"
import ChatUI from "./ChatUI/Chat"
import Profile from "./Profiles/Profile"
import Profiles from './Profiles/Profiles';
import {Route, Routes } from "react-router-dom";
import  Reset  from './LoginPage/Reset'
import  InitiateReset  from './LoginPage/InitiateReset'
import SignUp from './SignUp/SignUp';
import  Categories  from './Posts/Categories'
import  NotSigned  from './NotSignedIn/NotSigned'
import TermsOfService from './TOS/TermsOfService';
import SendMessage from './Posts/SendMessage';
import NotificationsView from './Notification/NotificationsView ';
function App() {
    return (
        <div style={{zoom:"100%"}}>
            {/* <Router> */}
            <Routes  >
                <Route exact path="/" element={<LandingPage/>}/>
                <Route exact path="/try" element={<NotSigned/>}/>
                <Route path="/log-in" element={<Login/>} />
                <Route path="/settings" element={<Overview />} />
                <Route path="/home" element={<Home />} />
                <Route path="/search" element={<Profiles />} />
                <Route path="/me" element={<Profile />} />
                <Route path="/chat" element={<ChatUI />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/reset" element={<Reset />} />
                <Route path="/initiate-reset" element={<InitiateReset />} />
                <Route path="/tos" element={<TermsOfService />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/notify-oct" element={<SendMessage />} />
                <Route path="/notifications" element={<NotificationsView />} />
            </Routes>
            {/* </Router> */}
        </div>
    )
}

export default App;