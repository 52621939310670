import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import config from '../scripts/config';
import NumberFormatter from '../scripts/StatsFormatter';
import { useTheme, useMediaQuery } from '@mui/material';

const TopMemerOfTheWeek = () => {
    const [memerStats, setMemerStats] = useState(null);
    const authToken = localStorage.getItem("oauth")
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const featureShown = localStorage.getItem("featureShown")


    useEffect(() => {
        const today = new Date();
        const dayOfWeek = today.getDay();

        // Check if today is Sunday (0 represents Sunday) or if feature has not been shown
        if (dayOfWeek === 0 || !featureShown) {
            fetch(`${config.apiHost}/api/v1/posts/top-memer-of-the-week`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
                .then(response => {
                    if (response.status === 204) {
                        console.log("No content available for this week.");
                        return;
                    }
                    return response.json();
                })
                .then(data => {
                    if (data) {
                        setMemerStats(data);
                    } else {
                        console.log("No memer stats available.");
                        setMemerStats(null);
                    }
                })
                .catch(error => {
                    console.error('Error fetching memer stats:', error);
                    setMemerStats(null);
                });
        }
    }, [authToken, featureShown]);

    // Check if memerStats is null or undefined
    const imageSrc = memerStats?.displayPicture === "https://memeskenya.com" || !memerStats?.displayPicture
        ? require("../visuals/mk.webp")
        : memerStats.displayPicture;


    if (!memerStats) {
        return null; // No data or not Sunday
    }

    return (
        <div style={{ border: "0.2px solid #272727", padding: "15px", borderRadius: "12px", backgroundColor: "#151515", width: isSmallScreen ? "80vw" : "290px" }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "9%", justifyContent: "left" }}>
                {!localStorage.getItem("featureShown") && (<Typography style={{ textAlign: "center", color: "grey", backgroundColor: "#1C2025", width: "100px", padding: "5px", borderRadius: "4px" }}>New Feature</Typography>)}
                {/* {localStorage.getItem("featureShown") && ( */}
                    <Typography style={{ textAlign: "left", color: "black", backgroundColor: "#3BE477", maxWidth: "200px", padding: "5px", borderRadius: "4px", fontWeight:"bold",maxHeight: "50px" , opacity:.9}}>Top Memer this Week</Typography>
                    {/* )} */}
            </div>
            {/* Close button aligned to the right */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CloseIcon style={{ cursor: "pointer", color: "#9EA6AD", visibility: 'hidden' }} />
            </div>

            {/* Centered avatar and name with badge */}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", marginBottom: "15px" }}>
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    badgeContent={
                        <Typography fontSize={"40px"}>🎉</Typography>
                    }
                >
                    <Avatar alt="Top Memer" src={imageSrc} style={{ width: "100px", height: "100px", border: "5px solid white" }} />
                </Badge>

                <Typography style={{ fontSize: "35px", color: "white", marginTop: "10px" }}>{memerStats.nickName}</Typography>
            </div>

            {/* Stats section with better alignment and spacing */}
            <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "20px", color: "white" }}>
                <div style={{ textAlign: "center" }}>
                    {/* <Typography color={"#08DBFA"} fontSize={"18px"}>{memerStats.totalPosts}</Typography> */}
                    <NumberFormatter value={memerStats.totalPosts} />
                    <Typography color={"#3BE477"} fontSize={"12px"}>Posts</Typography>
                </div>
                <div style={{ textAlign: "center" }}>
                    {/* <Typography color={"#08DBFA"} fontSize={"18px"}>{memerStats.totalViews}</Typography> */}
                    <NumberFormatter value={memerStats.totalViews} />
                    <Typography color={"#3BE477"} fontSize={"12px"}>Views</Typography>
                </div>
                <div style={{ textAlign: "center" }}>
                    {/* <Typography color={"#08DBFA"} fontSize={"18px"}>{memerStats.totalDownloads}</Typography> */}
                    <NumberFormatter value={memerStats.totalDownloads} />
                    <Typography color={"#3BE477"} fontSize={"12px"}>Downloads</Typography>
                </div>
                <div style={{ textAlign: "center" }}>
                    {/* <Typography color={"#08DBFA"} fontSize={"18px"}>{memerStats.totalLikes}</Typography> */}
                    <NumberFormatter value={memerStats.totalLikes} />
                    <Typography color={"#3BE477"} fontSize={"12px"}>Likes</Typography>
                </div>
            </div>
        </div>
    );
};

export default TopMemerOfTheWeek;
