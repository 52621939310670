import ReactDOM from 'react-dom/client';
import React from 'react';
import App from "./App"
import { BrowserRouter } from "react-router-dom";
import  {store,persistor}  from './store/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';

//create a route elements
const root = ReactDOM.createRoot(document.getElementById('root'));

//to use router, you need to make sure this BrowserRouter is included
root.render(
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* Your app components */}
      <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>
    </PersistGate>
  </Provider>
);