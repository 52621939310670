// OverlayMessage.js
import React from 'react';
import '../styles/OverlayMessage.css';
import CircularProgress from '@mui/material/CircularProgress';


const OverlayMessage = ({ message }) => {
    return (
        <div className="overlay">
            <div className="message-box">
                <p>{message}</p>
                <div className="scrollable-content">
                <CircularProgress id='circular' sx={{ 'svg circle': { stroke: 'rgb(81, 187, 254)' } }}
                  size={90}
                />
                </div>
            </div>
        </div>
    );
};

export default OverlayMessage;
