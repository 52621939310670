import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import personSlice from './personSlice';
import {
  persistStore, persistReducer
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to "localStorage"

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, personSlice.reducer);

export const store = configureStore({
  reducer: {
    person: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

export const persistor = persistStore(store);
