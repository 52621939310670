import jwtDecode from "jwt-decode";

function checkJWTValidity(token) {
  try {
    let decodedToken = jwtDecode(token);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export default checkJWTValidity;