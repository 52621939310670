import { useEffect, useState } from "react";
import { useRef } from "react";
import config from "../scripts/config";
import { Avatar, Box, CardMedia, debounce, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import PostEntity from "./Post";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// enables us to autoload when the user reaches end of current content
function AutoLoadOnScroll(props) {
    const targetRef = useRef(null);
    const [previousPostCount, setPreviousPostCount] = useState(0);
    const [likedPostIdsByMe, setLikedPostIdsByMe] = useState([]);
    const [memes, setMemes] = useState([])
    const [memePositions, setMemePositions] = useState([]);
    const [fromPosition, setFromPosition] = useState(0);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [empty, setEmpty] = useState(false);
    let page = 0;
    let size = 35;

    const fetchPosts = async () => {
        try {
            const apiUrl = `${config.apiHost}/api/v1/posts/getPosts?userId=${localStorage.getItem("memerId")}&page=${page}&size=${size}`;
            // console.log("size ", size)
            const headers = new Headers();
            headers.append('Accept', 'application/json');
            headers.append('Authorization', `Bearer ${localStorage.getItem("oauth")}`);

            const postsResponse = await fetch(apiUrl, { method: 'GET', headers });
            if (!postsResponse.ok) {
                throw new Error('Network response was not ok');
            } else {
                setEmpty(false);
                page = page + 1;
                // console.log(" Response ok", postsResponse.status);
            }



            const apiData = await postsResponse.json();
            // console.log("apidata ", apiData)
            if (apiData.empty) {
                page = page - 1;
                // console.log("Reached the last page.");
                return;
            }


            const newLikedPostIdsByMe = apiData.likedPostDTOs.content.map(post => post.postId);
            setLikedPostIdsByMe(prevIds => [...prevIds, ...newLikedPostIdsByMe]);

            setPreviousPostCount(props.loadedPosts.length);
            props.setLoadedPosts(prevLoadedPosts => {
                const allPosts = [...prevLoadedPosts, ...apiData.mediaPosts.content];
                const uniquePosts = allPosts.reduce((acc, current) => {
                    if (!acc.some(item => item.postId === current.postId)) {
                        acc.push(current);
                    }
                    return acc;
                }, []);
                return uniquePosts;
            });
            
            //checks if is last page (isLastPage boolean fron backend)
            if (apiData.lastPage) {
                setEmpty(true)
            }

            // props.setLoadedPosts(apiData.mediaPosts.content)
        } catch (error) {
            console.log("Error fetching data ", error);
        }
    };

    const fetchPostsDebounced = debounce(fetchPosts, 150); // Delay by 300ms
    useEffect(() => {
        const options = {
            root: null, // viewport
            rootMargin: '0px',
            threshold: 0.5, // 0.1 means 10% of the element must be visible
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    fetchPostsDebounced();
                    page = page + 1;
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, []);


    const removePost = (postId) => {
        props.setLoadedPosts(props.loadedPosts.filter(post => post.postId !== postId));
    };


    return (

        <div id="contentDiv">
            {/* <div id="contentDiv" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}> */}


            {/* This is the target element */}
            {props.loadedPosts.length > 0 ? (
                props.loadedPosts.map((item, index) => {
                    const isFirstInBatch = index === previousPostCount || (index !== 0 && (index - previousPostCount) % 5 === 0);

                    return (
                        <div key={index} >
                            <PostEntity
                                index={isFirstInBatch}
                                postId={item.postId}
                                name={item.nickName}
                                image={`https://www.memeskenya.com${item.imageUrl}`}
                                avatar={item.ownerDP}
                                time={item.timePosted}
                                likes={item.likeCount}
                                description={item.postTitle}
                                tag={item.tag}
                                downloads={item.downloads}
                                views={item.views}
                                setLatestviewedMeme={props.setLatestviewedMeme}
                                setLatestviewedMemePosition={props.setLatestviewedMemePosition}
                                latestviewedMemePosition={item.position}
                                localDownloads={props.localDownloads}
                                setLocalDownloads={props.setLocalDownloads}
                                localLikes={props.localLikes}
                                setLocalLikes={props.setLocalLikes}
                                setLocalUnLikes={props.setLocalUnLikes}
                                likedByMe={likedPostIdsByMe.includes(item.postId)}
                                setLikedPostIdsByMe={setLikedPostIdsByMe}
                                notsignedIn={false}
                                batchUpdate={props.batchUpdate}
                                setViews={props.setViews}
                                localViews={props.views}
                                removePost={removePost}
                                senderId={item.owner}
                                isVerified={item.verified}
                                memes={memes}
                                setMemes={setMemes}
                                memePositions={memePositions}
                                setMemePositions={setMemePositions}
                                fromPosition={fromPosition}
                                setFromPosition={setFromPosition}
                                isTagView={false}
                            />
                        </div>
                    );
                })

            ) : (
                !empty && (<p>Getting content. Please wait...</p>)
            )}
            {empty ?
                (
                    <div style={{
                        width: isSmallScreen ? "85vw" : "400px",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center", // Center items vertically
                        margin: "0 auto", // Center the div itself horizontally
                        flexDirection: "column",// Stack items vertically
                    }}>
                        <Card sx={{ width: isSmallScreen ? "85vw" : "400px", backgroundColor: "transparent", borderRadius: "8px" }}>
                            {/* <p style={{ fontSize: "25px", justifyContent: "center", textAlign: "center" }}>MemesKenya</p> */}
                            <CardMedia
                                style={{ height: isSmallScreen ? "300px" : "360px" }}
                                image={require('../visuals/nocontent_nomemes.webp')}
                                title="MemesKenya"
                            />
                            <CardContent style={{ backgroundColor: "#0D0E0F", color: "white" }}>
                                <Typography variant="body2" style={{ color: "white", textAlign: "center", fontSize: "20px" }}>
                                    No more memes😄
                                    <br />
                                    <a style={{ color: "grey", fontSize: "10px" }} href="https://www.freepik.com/free-vector/hand-drawn-no-data-concept_55024599.htm#fromView=search&page=2&position=44&uuid=b4288d60-16b8-482d-a680-aadc2aa7085e">Image by pikisuperstar on Freepik</a>
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                )
                :
                (
                    <div ref={targetRef} style={{ width: isSmallScreen ? "85vw" : "500px", color: "white" }}>
                        <Box sx={{ display: "flex" }}>
                            <Skeleton variant="circular"
                                sx={{ bgcolor: 'grey.900', margin: "5%" }} >
                                <Avatar />
                            </Skeleton>
                            <Skeleton variant="rectangular"
                                sx={{
                                    bgcolor: 'grey.900',
                                    height: "20px",
                                    marginTop: "8%", borderRadius: "6px"
                                }} >
                                <Typography sx={{ width: "180px" }}></Typography>
                            </Skeleton>
                        </Box>
                        <Skeleton variant="rectangular"
                            style={{ backgroundColor: '#141414', margin: "5%", marginTop: "1%", borderRadius: "6px", width: isSmallScreen ? "85vw" : "500px", height: "199px" }}
                        >
                        </Skeleton>
                    </div>
                )
            }
        </div >
    );
}

export default AutoLoadOnScroll;