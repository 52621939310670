import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import MemeskenyaMessage from './Memeskenyamessage';
import config from '../scripts/config';
import TopMemerOfTheWeek from '../Posts/TopMemerOfTheWeek ';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const NotificationsView = () => {
    const [memeskenyaMessages, setMemeskenyaMessages] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const authToken = localStorage.getItem("oauth");
    const memerId = localStorage.getItem("memerId");
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/home'); // Navigate to the home page
    };
    const updatePosition = (memerId, position) => {
        fetch(`${config.apiHost}/api/v1/messages/updateMessagePosition?userId=${memerId}&newPosition=${position}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .catch(error => {
                console.error('Error updating position:', error);
            });
    };

    useEffect(() => {
        fetch(`${config.apiHost}/api/v1/messages/getNewMessages?userId=${memerId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setMemeskenyaMessages(data);
            });
    }, [memerId]);

    const removeMemesKenyaMessage = (messagetoRemove) => {
        setMemeskenyaMessages(prevMessages =>
            prevMessages.filter(message => message !== messagetoRemove)
        );
    };


    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setSelectedMessage(null);
    };

    return (
        <>
            <div style={{
                position: "fixed",
                top: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",       // Center content vertically
                alignItems: "center",           // Center content horizontally
                padding: "10px",
            }}>
                <div style={{ display: "flex", gap: "50px", width: "100vw", marginBottom: "4%" }}>
                       {/* Back button */}
                <div onClick={handleBackClick} style={{  cursor: "pointer" }}>
                    <KeyboardBackspaceIcon style={{ color: 'white', fontSize: '24px' }} />
                </div>
                    <Typography style={{
                        color: "white",
                        fontSize: "20px",
                        textAlign: "center",
                        marginBottom: "2%"
                    }}>
                        Notifications
                    </Typography>
                </div>
                <div style={{
                    width: "80vw",
                    display: "flex",
                    justifyContent: "center", // Ensure TopMemerOfTheWeek is centered
                }}>
                    {new Date().getDay() === 0 && <TopMemerOfTheWeek />}
                </div>
                <div style={{
                    height: "75vh",
                    width: "100%",
                    backgroundColor: "transparent",
                    overflowY: "auto",
                    padding: "5px",
                    marginTop: "2%",
                    scrollbarWidth: "thin", // For Firefox
                    scrollbarColor: "grey transparent", // For Firefox
                }}>
                    {memeskenyaMessages.length > 0 ? (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {memeskenyaMessages.map((message, index) => (
                                <MemeskenyaMessage
                                    key={index}
                                    messageTitle={message.title}
                                    updatePosition={updatePosition}
                                    position={message.id}
                                    memerId={memerId}
                                    parentMessage={message}
                                    removeMessage={removeMemesKenyaMessage}
                                    // onClick={() => handleCardClick(message)} // Add onClick handler
                                />
                            ))}
                        </div>
                    ) : (
                        <Typography style={{
                            fontSize: "35px",
                            textAlign: "center",
                            color: "grey",
                            margin: "auto"
                        }}>
                            No notifications
                        </Typography>
                    )}
                </div>
            </div>
            {selectedMessage && (
                <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                    <DialogContent style={{ overflowY: 'scroll', maxHeight: '80vh' }}>
                        <Typography variant="h6" color="textPrimary">
                            {selectedMessage.title}
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                            {selectedMessage.message}
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default NotificationsView;
