import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Card, CardContent, CardMedia, Typography } from "@mui/material"
import React from "react"
import "../styles/Profile.css"
import Guide from "../Video/Guide.mp4"
import geh from "../visuals/geh.jpeg"
import Wambo from "../visuals/wambo.jpg"


export default function Profile() {
    return (
        <div>
            {/* back arrow */}
            <div>
                <FontAwesomeIcon icon={faChevronCircleLeft} size="2x" color="#45ADE4"
                    style={{
                        marginTop: "5%",
                        marginLeft: "4%"
                    }} />
            </div>
            {/* Avatar and Info */}
            <div style={{ display: "flex", marginLeft: "10%", marginTop: "5%", marginBottom: "5%" }}>
                <Avatar alt="Person" src={geh} style={{ width: "90px", height: "90px" }} />
                {/* Avatar Info */}
                <div style={{ display: "block", marginLeft: "6%", marginTop: "5%" }}>
                    <Typography style={{ fontSize: "20px" }}>Shi-Sea-A</Typography>
                    <p style={{ marginTop: "-3%", marginBottom: "3%" }}>@james shisia six</p>
                    <p style={{ marginTop: "-3%" }}>Passionate about Memes</p>
                </div>
            </div>
            {/* Profile extra social info */}
            <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                {/* Followers */}
                <div style={{ marginTop: "0%", marginLeft: "7%", textAlign: "center" }}>
                    <Typography style={{ fontSize: "25px" }} color="#45ADE4">24K</Typography>
                    <p style={{ marginTop: "-1%" }} >Fans</p>
                </div>
                {/* Following */}
                <div style={{ marginTop: "0%", marginLeft: "7%", textAlign: "center" }}>
                    <Typography style={{ fontSize: "25px" }} color="#45ADE4">2K</Typography>
                    <p style={{ marginTop: "-1%" }} >Following</p>
                </div>
                {/* Posts */}
                <div style={{ marginTop: "0%", marginLeft: "7%", textAlign: "center" }}>
                    <Typography style={{ fontSize: "25px" }} color="#45ADE4">2099</Typography>
                    <p style={{ marginTop: "-1%" }} >Posts</p>
                </div>
            </div>
            {/* buttons */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5%" }}>
                {/* Follow button */}
                <button style={{
                    marginRight: "10%",
                    width: "35%",
                    height: "45px",
                    border: "0px solid #45ADE4",
                    borderRadius: "13px",
                    color: "aliceblue",
                    backgroundColor: "#45ADE4",
                    fontSize: "18px"
                }}>Follow</button>
                {/* Message button */}
                <button style={{
                    width: "35%",
                    height: "45px",
                    border: "0px solid #45ADE4",
                    borderRadius: "13px",
                    color: "aliceblue",
                    backgroundColor: "#45ADE4",
                    fontSize: "18px"
                }}>Message</button>
            </div>
            {/* posts (images and short clips) */}
            <div>
                <div style={{ justifyContent: "left", marginBottom: "-7%", alignItems: "center", display: "flex" }}>
                    <p style={{ marginLeft: "10%", marginTop: "10%", fontSize: "20px" }}>Uploads</p>
                </div>
                <div style={{ display: "grid", justifyContent: "center", gap: "1px", alignItems: "center", gridTemplateColumns: "repeat(2,1fr)" }}>
                    {/* MUI Player */}
                    <div style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "block",
                        marginTop: "-10%",
                        marginLeft: "3%",
                        width: "165px",
                    }}>
                        <div style={{ marginTop: "25%" }}>
                        </div>
                        <Card sx={{ maxWidth: 345, height: 100}}>
                            <CardMedia
                                component="video"
                                image={Guide}
                                sx={{
                                    width: "100%",
                                    height: "150%",
                                    borderRadius: 0,
                                    border: "4px solid #45ADE4",
                                    backgroundColor:"black",
                                    marginTop: "-16%",
                                    marginLeft:"-1.8%"
                                }}
                                autop
                                title='title'
                            />
                        </Card>
                    </div>

                    <div style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "block",
                        marginTop: "-10%",
                        marginLeft: "3%",
                        width: "165px",
                    }}>
                        <div style={{ marginTop: "25%" }}></div>
                        <Card sx={{ maxWidth: 345, height: 100}}>
                            <CardMedia
                                component="video"
                                image={Guide}
                                sx={{
                                    width: "100%",
                                    height: "150%",
                                    borderRadius: 0,
                                    border: "4px solid #45ADE4",
                                    backgroundColor:"black",
                                    marginTop: "-16%",
                                    marginLeft:"-1.8%"
                                }}
                                title='title'
                            // controls
                            />
                        </Card>
                    </div>
                    <div style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "block",
                        marginTop: "-10%",
                        marginLeft: "3%",
                        width: "165px",
                    }}>
                        <div style={{ marginTop: "25%" }}></div>
                        <Card sx={{ maxWidth: 345, height: 100}}>
                            <CardMedia
                                component="video"
                                image={Guide}
                                sx={{
                                    width: "100%",
                                    height: "150%",
                                    borderRadius: 0,
                                    border: "4px solid #45ADE4",
                                    backgroundColor:"black",
                                    marginTop: "-16%",
                                    marginLeft:"-1.8%"
                                }}
                                title='title'
                            // controls
                            />
                        </Card>
                    </div>

                    {/* MUI Player */}
                    <div style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "block",
                        marginTop: "-10%",
                        marginLeft: "3%",
                        width: "165px",
                    }}>
                        <div style={{ marginTop: "25%" }}>
                        </div>
                        <Card sx={{ maxWidth: 345, height: 100 }}>
                            <CardMedia
                                component="img"
                                image={Wambo}
                                sx={{
                                    width: "100%",
                                    height: "150%",
                                    borderRadius: 0,
                                    border: "4px solid #45ADE4",
                                    backgroundColor:"black",
                                    marginTop: "-16%",
                                    marginLeft:"-1.8%"
                                }}
                                
                                title='title'
                            // controls
                            />
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}