import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import config from '../scripts/config';
import TopMemerOfTheWeek from '../Posts/TopMemerOfTheWeek ';
import { useTheme, useMediaQuery } from '@mui/material';


const PhoneViewTopMemer = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const authToken = localStorage.getItem("oauth");
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const featureShown = localStorage.getItem("featureShown")

    // Function to check if the current Sunday popup has been dismissed
    const hasDismissedPopup = () => {
        const dismissedOn = localStorage.getItem("dismissedTopMemerPopupOn");
        const lastDismissedDate = new Date(dismissedOn);
        const today = new Date();
        // If it's the same Sunday, return true; otherwise, false
        return today.getDay() === 0 && today.toDateString() === lastDismissedDate.toDateString();
    };

    // Function to mark the popup as dismissed
    const dismissPopup = () => {
        localStorage.setItem("dismissedTopMemerPopupOn", new Date().toDateString());
        localStorage.setItem("featureShown", true)
        setIsDialogOpen(false);
    };

    useEffect(() => {
        const today = new Date();

        // Show popup if it's Sunday and the popup has not been dismissed today
        if ((today.getDay() === 0 && !hasDismissedPopup()) || !featureShown) {
            fetch(`${config.apiHost}/api/v1/posts/top-memer-of-the-week`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    // console.log("Top Memer Data:", data);
                    setIsDialogOpen(true);
                })
                .catch(error => console.error('Error fetching top memer:', error));
        }
    }, []);

    return (
        <>
            {/* Rest of your NotificationsView code */}

            <Dialog
                open={isDialogOpen}
                onClose={dismissPopup}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        margin: '0',
                        padding: '20px',
                        maxHeight: '100vh', // Prevent scrolling by keeping the height within the viewport
                        backgroundColor: "transparent"
                    },
                }}
            >
                <DialogContent
                    style={{
                        padding: '0',
                        margin: '0',
                        overflow: 'hidden', // Prevent content overflow
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >

                    <TopMemerOfTheWeek />
                    <Button
                        onClick={dismissPopup}
                        variant="contained"
                        color="success"
                        style={{
                            marginTop: '20px',
                            width: isSmallScreen ? "85vw" : "300px",
                            height: "50px",
                            backgroundColor: "#DBE8F9",
                            border: "1px solid #08DBFA",
                            opacity: 0.8,
                            transition: "background-color 0.3s ease, opacity 0.3s ease",
                            cursor: "pointer",
                            color: "black"
                        }}
                        onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = "#3BE477";
                            e.currentTarget.style.opacity = "1";
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = "#08DBFA";
                            e.currentTarget.style.opacity = "0.8";
                        }}
                    >
                        Dismiss
                    </Button>

                </DialogContent>
            </Dialog>

        </>
    );
};

export default PhoneViewTopMemer;
