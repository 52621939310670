import React, { useEffect, useRef, useState } from "react";
import '../styles/LoginPage.css'
import aujusi from "../visuals/jusifrut.jpg"
import { Avatar, Box, Button, Fade, IconButton, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../store/personSlice";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import config from "../scripts/config";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import OverlayMessage from "../scripts/OverlayMessage";
export default function Login() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedImagefile, setSelectedImageFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');

    const [originalUsername, setOriginalUsername] = useState('');
    const [originalEmail, setOriginalEmail] = useState('');
    const [originalPassword, setOriginalPassword] = useState('');
    const [originalProfileName, setOriginalProfileName] = useState('');
    const storedUser = useSelector((state) => state.person);
    const [showPassword, setShowPassword] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [activeName, setActiveName] = useState(storedUser.name)
    const [isSuccess, setIsSuccess] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [profileNameError, setProfileNameError] = useState('');
    const [usernameError, setusernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [preview, setPreview] = useState(false);
    const [previewImage, setPreviewImage] = useState(localStorage.getItem("avatar"));
    const [avatar, setAvatar] = useState(localStorage.getItem("avatar"));
    // State to track the password input value
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [profileName, setProfileName] = useState('');
    const [email, setEmail] = useState('');
    const [userDetails, setUserDetails] = useState({
        email: '',
        profileName: '',
        username: '',
        password: ''
    });



    // Function to toggle the password visibility
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setUserDetails({
            ...userDetails,
            [e.target.name]: noSpacesValue
        });
        setEmail(noSpacesValue)
        setEmailError('')
    };

    const handleChangeProfileName = (e) => {
        const value = e.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setUserDetails({
            ...userDetails,
            [e.target.name]: noSpacesValue
        });
        setProfileName(noSpacesValue)
        setProfileNameError('')
    };

    const handleChangeUsername = (e) => {
        const value = e.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setUserDetails({
            ...userDetails,
            [e.target.name]: noSpacesValue
        });
        setUsername(noSpacesValue)
        setusernameError('')
    };

    const handleChangePassword = (e) => {
        const value = e.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setUserDetails({
            ...userDetails,
            password: noSpacesValue
        });
        setPassword(noSpacesValue)
        setPasswordError(''); // Clear error when user starts typing
    };

    const handleUpdate = async () => {

        const savedImagePath = await uploadAvatar();

        if (!savedImagePath) {
            console.error("Avatar upload failed");
        } else {
            setAvatar(savedImagePath)
            localStorage.setItem("avatar", savedImagePath)
        }
        // Regex for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const usernameChanged = userDetails.username !== originalUsername;
        const emailChanged = userDetails.email !== originalEmail;
        let passwordChanged = userDetails.password !== originalPassword;
        let profileNameChanged = userDetails.profileName !== originalProfileName;
        if (password === '') {
            passwordChanged = false;
        }
        if (passwordChanged && password.length < 6) {
            setPasswordError("Password can't be less than 6 characters");
            return;
        }
        if (username.length < 5) {
            setusernameError("Username can't be less than 5 characters");
            return;
        }
        if (emailChanged && !emailRegex.test(email)) {
            setEmailError("Check email format");
            return;
        }
        if (profileNameChanged && profileName.length < 5) {
            setProfileNameError("Handle can't be less than 5 characters");
            return;
        }
        setShowOverlay(true);
        const resetPayload = {
            password: userDetails.password,
            email: userDetails.email,
            nickName: userDetails.profileName,
            userName: userDetails.username,
            usernameChanged, // Add this to indicate if the username has changed
            emailChanged, // Add this to indicate if the email has changed
            originalEmail: storedUser.email,
            originalUsername: storedUser.temporaryUsername,
            originalPassword: storedUser.temporaryPassword,
            passwordChanged
        };

        const authToken = localStorage.getItem("oauth");
        const memerId = localStorage.getItem("memerId");
        const resetApiUrl = `${config.apiHost}/api/v1/password/resetLoggedUser?userId=${memerId}`;

        try {
            const response = await fetch(resetApiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(resetPayload)
            });

            if (response.ok) {
                setShowOverlay(false);
                const data = await response.json();
                setActiveName(resetPayload.nickName)
                setIsSuccess(true);
                // Update user details in Redux state if needed
                // Create a user model
                const user = {
                    name: resetPayload.nickName,
                    memerId: resetPayload.userId,
                    authToken: resetPayload.token,
                    image: resetPayload.image,
                    email: resetPayload.email,
                    temporaryUsername: resetPayload.userName,
                    expiredPassword: resetPayload.password
                };

                // Save the values to the Redux state
                dispatch(userActions.setDetails(user));


                if (profileNameChanged && resetPayload.nickName.length > 0) {
                    setUserDetails({
                        ...userDetails,
                        profileName: resetPayload.nickName
                    });
                    setOriginalProfileName(resetPayload.nickName)
                }

                if (passwordChanged && resetPayload.password.length > 0) {
                    setUserDetails({
                        ...userDetails,
                        password: resetPayload.password
                    });
                    setOriginalPassword(resetPayload.password)

                }
                if (emailChanged && resetPayload.email.length > 0) {
                    setUserDetails({
                        ...userDetails,
                        email: resetPayload.email
                    });
                    setOriginalEmail(resetPayload.email);
                }
                if (usernameChanged && resetPayload.userName.length > 0) {
                    setUserDetails({
                        ...userDetails,
                        username: resetPayload.userName
                    });

                    setOriginalUsername(resetPayload.userName);

                }

                setTimeout(() => {
                    setIsSuccess(false);
                }, 5000)
            } else if (response.status === 401) {
                console.error('Unauthorized: Invalid or expired token');
                // Handle re-authentication or token refresh if applicable
            } else {
                const errorData = await response.json();
                console.error('Error:', errorData);
                setShowOverlay(false);
            }
        } catch (error) {
            console.error('Network Error:', error);
            setShowOverlay(false);
        }
    };

    //enabling and disabling the data editting
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (event) => {
        if (selectedImagefile) {
            setPreview(false)
            setSelectedImageFile(null);
        }
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Revoke the old preview URL to avoid memory leaks
            if (previewImage) {
                URL.revokeObjectURL(previewImage);
            }
            setPreview(true)
            // Set the new selected file and create a preview URL
            setSelectedImageFile(selectedFile);
            setPreviewImage(URL.createObjectURL(selectedFile));
        }
    };


    const uploadAvatar = async () => {
        const memerId = localStorage.getItem("memerId");
        const authToken = localStorage.getItem("oauth");

        if (selectedImagefile) {
            const formData = new FormData();
            formData.append("file", selectedImagefile);
            formData.append("memerId", memerId);

            const changeAvatarUrl = `${config.apiHost}/api/v1/Memers/changeAvatar?memerId=${memerId}`;

            try {
                const response = await fetch(changeAvatarUrl, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    },
                    body: formData
                });

                if (response.ok) {
                    const savedPath = await response.text(); // Get the saved image path
                    return `https://www.memeskenya.com${savedPath}`; // Return the saved path
                } else {
                    console.error('Failed to update avatar');
                    return null;
                }
            } catch (error) {
                console.error('Network Error:', error);
                return null;
            }
        }
        return null;
    };




    useEffect(() => {
        if (storedUser) {
            setUserDetails({
                email: storedUser.email,
                profileName: storedUser.name,
                username: storedUser.temporaryUsername,
                password: storedUser.expiredPassword
            });
            setEmail(storedUser.email);
            setProfileName(storedUser.name)
            setUsername(storedUser.temporaryUsername)
            setSelectedImage(storedUser.imageUrl);
            setOriginalUsername(storedUser.temporaryUsername); // Store the original username
            setOriginalEmail(storedUser.email); // Store the original email
            setOriginalPassword(storedUser.expiredPassword); // Store the original password
            setOriginalProfileName(storedUser.name);
        }
    }, [storedUser]);




    const goHome = () => {
        navigate('/home');
    };

    const fieldStyle = {
        margin: "5%",
        marginLeft: "0",
        marginLeft: "10%",
        color: "#DBE8F9",
        fontSize: "20px"
    }


    const inputStyle = {
        marginTop: "2%",
        height: "40px",
        width: isSmallScreen ? "300px" : "400px",
        borderRadius: "2px",
        backgroundColor: "transparent",
        border: ".5px solid #DBE8F9",
        fontSize: "18px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
        maxWidth: "1200px",
        margin: "0 auto",
        color: "grey"
    }

    const inputStylePassword = {
        marginTop: "2%",
        height: "40px",
        width: isSmallScreen ? "250px" : "350px",
        borderRadius: "2px",
        backgroundColor: "transparent",
        border: ".5px solid #DBE8F9",
        fontSize: "18px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
        maxWidth: "1200px",
        margin: "0 auto",
        color: "grey"
    }

    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        maxWidth: "1200px",
        margin: "0 auto"
    };

    const containerStyleInput = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
        maxWidth: "1200px"
    }



    return (
        <>
            <Fade direction="right" in timeout={{ enter: 3000 }} mountOnEnter unmountOnExit>
                <div style={containerStyle}>
                    {showOverlay && (
                        <div>
                            <OverlayMessage message={"Please wait a second as we save your credentials . . ."} />
                        </div>
                    )}
                    <div style={{ position: "sticky", top: 0, width: "100%", backgroundColor: "black", zIndex: 1000, textAlign: "center", display: "flex", alignItems: "center", height: "60px" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '80vw', height: '6vh', paddingTop: '5px', backgroundColor: 'black', maxWidth: '1200px', position: 'relative', alignItems: 'center' }}>
                            <Box sx={{ position: 'absolute', left: '10px' }}>
                                <ArrowBackIcon onClick={goHome} sx={{ color: 'whitesmoke', fontSize: '40px', cursor: 'pointer' }} />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '30px', color: 'white', marginRight: '0px', fontWeight: "bold" }}>
                                    Memes
                                </Typography>
                                <Typography sx={{ fontSize: '30px', color: '#45ADE4', fontWeight: "bold" }}>
                                    Kenya
                                </Typography>
                            </Box>
                        </Box>
                    </div>

                    <div style={{ justifyContent: "center", display: "flex", margin: "0%" }}>
                        <div style={{ position: "relative", width: "150px", height: "150px" }}>
                            <Avatar alt={activeName} src={preview ? previewImage : avatar} sx={{ width: "100%", height: "100%", backgroundColor: "grey", border: "5px solid white" }}>{activeName}</Avatar>
                            <Box display="flex" flexDirection="column" alignItems="center" margin="2%" >
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="image-upload"
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleImageChange}
                                />
                                <Button
                                    variant="contained"
                                    onClick={handleButtonClick}
                                    color="primary"
                                    style={{
                                        position: "absolute",
                                        width: "20px",
                                        height: "40px",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        justifyContent: "center",
                                        display: "flex",
                                        backgroundColor: "transparent",
                                        borderRadius: "30px"
                                    }}
                                    startIcon={<AccountCircleIcon sx={{ width: 80, height: 80, marginLeft: "12%" }} />}
                                >
                                </Button>
                            </Box>

                        </div>
                    </div>
                    <div style={containerStyleInput}>
                        <p>Click the Icon to Change the Profile Picture</p>
                        {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                        <label style={fieldStyle}>
                            Email: <input name="email" value={email} onChange={handleChangeEmail} style={inputStyle} />
                        </label>
                        {profileNameError && <p style={{ color: 'red' }}>{profileNameError}</p>}
                        <label style={fieldStyle}>
                            Profile Name: <input name="profileName" value={profileName} onChange={handleChangeProfileName} style={inputStyle} />
                        </label>
                        {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>}
                        <label style={fieldStyle}>
                            Username: <input name="username" value={username} onChange={handleChangeUsername} style={inputStyle} />
                        </label>
                        {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
                        <div style={{ display: "flex", justifyContent: "center", maxWidth: isSmallScreen ? "300px" : "480px" }}>
                            <label style={fieldStyle}>
                                New Password: &nbsp; (If not changing password, leave it blank) <input name="password" type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    value={password} placeholder="Enter new password" onChange={handleChangePassword} style={inputStylePassword} />
                            </label>
                            <IconButton sx={{ color: "white" }}
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility sx={{ fontSize: "30px" }} /> : <VisibilityOff sx={{ fontSize: "30px" }} />}
                            </IconButton>
                        </div>

                    </div>
                    <div style={{ paddingBottom: "20px" }}>
                        <button onClick={handleUpdate} style={{
                            border: "1px solid #45ADE4", fontSize: "20px", backgroundColor: isSuccess ? "green" : "#45ade4", color: "white", cursor: "pointer", borderRadius: "8px",
                            height: "50px",
                            width: isSmallScreen ? "300px" : "400px", textAlign: "center", justifyContent: "center"
                        }}>
                            {isSuccess ? "Update SuccessFully" : "Update Details"}
                        </button>

                    </div>
                </div>
            </Fade>
        </>
    )
}