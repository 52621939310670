import { Avatar } from "@mui/material";
import React from "react";
import "../styles/SideMenu.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faHome, faInfoCircle, faPowerOff, faTags, faX } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import NewFeatureAnnouncement from "../Notification/NewFeature";
export const deleteLocalStorageItems = () => {
    localStorage.removeItem('oauth');
    localStorage.removeItem('persist:root');
    localStorage.removeItem('loaderShown');
}
export default function Menu(props) {
    const firstLetter = props.name ? props.name.charAt(0) : '';
    const navigate = useNavigate();
    const link = localStorage.getItem("avatar");
    const handleAvatarClick = () => {
        navigate('/settings');
    };


    return (
        <div id="sideMenuContainer">
            <div style={{
                backgroundColor: "black",
                border: ".5px solid black",
                width: "200px",
                height: "100vh",
                position: "fixed",
                top: "0",
                left: "0",
                paddingLeft: "15%",
                textAlign: "left",
                paddingTop: "10vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "top-center",
                justifyContent: "flex-start", // Aligns items to the top
                alignItems: "flex-start", // Aligns items to the left
                borderTopRightRadius: '15px', // Curves the top-right corner
                borderBottomRightRadius: '10px' // Curves the bottom-right corner
            }}

            >
                <button id="closeButton" onClick={props.closeAction}><FontAwesomeIcon icon={faX} color="#45ADE4" /></button>
                <div style={{ display: "flex", marginLeft: "15%" }}>
                    <Avatar alt="name"
                        src={link}
                        onClick={handleAvatarClick}
                        style={{ width: "60px", height: "60px", cursor: "pointer", backgroundColor: "white", color: "black" }}>
                        {firstLetter} 
                    </Avatar>
                </div>

                <div style={{ display: "block", marginLeft: "5%" }}>
                    {/* Home icon */}
                    <FontAwesomeIcon icon={faHome} style={{ marginLeft: "0%", color: "#45ADE4" }} />
                    <button className="buttons" onClick={props.Home}>
                        <Link to="/home" style={{ textDecoration: "none", color: "aliceblue" }}>Home</Link>
                    </button>
                    <br />
                    {/* Messages icon */}
                    <FontAwesomeIcon icon={faTags} style={{ marginLeft: "0%", color: "#45ADE4" }} />
                    <button className="buttons" onClick={props.Messages}>
                        <Link to="/categories" style={{ textDecoration: "none", color: "aliceblue" }}>Categories</Link>
                    </button>

                    <br />
                    
                    <FontAwesomeIcon icon={faBell} style={{ marginLeft: "0%", color: "#45ADE4" }} />
                    <button className="buttons" onClick={props.Messages}>
                        <Link to="/notifications" style={{ textDecoration: "none", color: "aliceblue" }}>Announcements</Link>
                    </button>

                    <br />
                    {/* Settings icon */}
                    <FontAwesomeIcon icon={faCog} style={{ marginLeft: "0%", color: "#45ADE4" }} />
                    <button className="buttons" onClick={props.Settings}>
                        <Link to="/settings" style={{ textDecoration: "none", color: "aliceblue" }}>Settings</Link>
                    </button>
                    <br />
                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: "0%", color: "#45ADE4" }} />
                    <button className="buttons" onClick={props.Friends}>
                        <Link to="/tos" style={{ textDecoration: "none", color: "aliceblue" }}>Terms of Service</Link>
                    </button>
                    <br />
                    <div style={{ marginTop: "5%" }}>
                        <FontAwesomeIcon icon={faPowerOff} color="red" style={{ marginRight: "5%" }} />
                        <button className="buttons" onClick={deleteLocalStorageItems}>
                            <Link to="/log-in" style={{ textDecoration: "none", color: "aliceblue" }}>Log Out</Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}