import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function MemeskenyaMessage({ messageTitle, position, updatePosition, memerId, removeMessage, parentMessage }) {

    const [open, setOpen] = React.useState(false);

    const handleDismiss = () => {
        // Call the function to update the position
        updatePosition(memerId, position);
        removeMessage(parentMessage)
    };

    const handleCardClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Card sx={{
                maxWidth: 345,
                maxHeight: 50,
                backgroundColor: "#272727",
                color: "white",
                marginBottom: "5%",
                display: "flex",
                justifyContent: "space-between", // Ensures spacing between content
                alignItems: "center"
                , cursor: "pointer"             // Vertically centers content
            }}
                onClick={handleCardClick}  // Open the modal on card click

            >
                <CardMedia
                    style={{ maxWidth: "50px" ,opacity: 0.3}}
                    component="img"
                    alt="memeskenya notification"
                    width="100%"  // Ensures the image scales proportionally within the maxWidth
                    height="auto" // Maintains the aspect ratio
                    image={require("../visuals/mk.webp")}
                />
                <CardContent sx={{ flexGrow: 1 }}>  {/* Allows content to grow and take available space */}
                    <Typography variant="body2" color="#C4CDD5" style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: "170px",
                        fontSize: "16px"
                    }}>
                        {messageTitle}
                    </Typography>
                </CardContent>
                <CardActions sx={{ marginLeft: "auto" }}>  {/* Pushes the button to the right */}
                    <CloseIcon
                        size="small"
                        sx={{ color: "#C4CDD5", cursor: "pointer" }}
                        onClick={handleDismiss}  // Add onClick event
                    />
                </CardActions>
            </Card>
            {/* Dialog for showing the full notification message */}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle style={{ fontSize: "25px" }}>{parentMessage.title}</DialogTitle>
                <DialogContent style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <Typography variant="body1" color="textPrimary">
                        {parentMessage.message}
                        
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
