import { Avatar } from "@mui/material";
import React from "react";
import "../styles/SideMenuWeb.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faInfoCircle, faPowerOff, faTags, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import { deleteLocalStorageItems } from "./SideMenu";
export default function Menu(props) {
    const firstLetter = props.nickName ? props.nickName.charAt(0) : '';
    const link = localStorage.getItem("avatar");

    const postMeme = () => {
        // Perform any additional actions you want here
        if (props.closeAction) {
            props.closeAction();
        }

        // Call the original postAction from props
        if (props.postAction) {
            props.postAction();
        }
    };


    return (
        <div id="firstWebDiv">
            <div style={{ display: "block", justifyContent: "left", textAlign: "left" }}>
                <div style={{ marginTop: "0%" }}>
                    <Avatar alt={props.nickName}
                        src={`${link}`}
                        style={{ width: "80px", height: "80px", cursor: "pointer" }} >
                        {firstLetter}
                    </Avatar>
                </div>
                <div style={{ marginTop: "5%" }}>
                    <FontAwesomeIcon icon={faHome} style={{ color: "#45ADE4" }} />
                    <button className="btns">
                        <Link to="/home" style={{ textDecoration: "none", color: "aliceblue" }}>Home</Link>
                    </button>
                </div>
                <div style={{ marginTop: "5%" }}>
                    <FontAwesomeIcon icon={faUserFriends} style={{ color: "#45ADE4" }} />
                    <button className="btns">
                        <Link to="/settings" style={{ textDecoration: "none", color: "aliceblue", cursor: "-moz-grab" }}>Settings</Link>
                    </button>
                </div>
                <div style={{ marginTop: "5%" }}>
                    <FontAwesomeIcon icon={faTags} style={{ color: "#45ADE4" }} />
                    <button className="btns">
                        <Link to="/categories" style={{ textDecoration: "none", color: "aliceblue", cursor: "-moz-grab" }}>Categories</Link>
                    </button>
                </div>
                <div style={{ marginTop: "5%" }}>
                    <PostAddRoundedIcon style={{ color: "#45ADE4", cursor: "pointer" }} />
                    <button className="buttons" onClick={postMeme} style={{ cursor: "pointer" }}>
                        Post Meme
                    </button>
                </div>
                <div style={{ marginTop: "5%" }}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ color: "#45ADE4" }} />
                    <button className="btns" >
                        <Link to="/tos" style={{ textDecoration: "none", color: "aliceblue" }}>Terms of Service</Link>
                    </button>
                </div>

                <div style={{ marginTop: "5%" }}>
                    <FontAwesomeIcon icon={faPowerOff} color="red" style={{}} />
                    <button className="btns" onClick={deleteLocalStorageItems}  >
                        <Link to="/log-in" style={{ textDecoration: "none", color: "aliceblue" }}>Log Out</Link>
                    </button>
                </div>
            </div>
        </div>
    )
}