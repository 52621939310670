import { Button, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import "../styles/SignUp.css";
import InputIcon from '@mui/icons-material/Input';
import LoginIcon from '@mui/icons-material/Login';
import { Link, useNavigate } from "react-router-dom";
import config from "../scripts/config";
import OverlayMessage from "../scripts/OverlayMessage";

export default function SignUp() {
    const [error, setError] = useState('');

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [nickName, setNickName] = useState('');
    const [email, setEmail] = useState('');

    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [nickNameError, setNickNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showOverlay, setShowOverlay] = useState(false);

    const handleSetUsername = (event) => {
        const value = event.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setUsername(noSpacesValue);
        setUsernameError(''); // Clear error when user starts typing
    };

    const handleSetPassword = (event) => {
        const value = event.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setPassword(noSpacesValue);
        setPasswordError(''); // Clear error when user starts typing
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setConfirmPassword(noSpacesValue);
        setConfirmPasswordError(''); // Clear error when user starts typing
    };

    const handleSetNickname = (event) => {
        const value = event.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setNickName(noSpacesValue);
        setNickNameError(''); // Clear error when user starts typing
    };

    const handleSetEmail = (event) => {
        const value = event.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setEmail(noSpacesValue );
        setEmailError(''); // Clear error when user starts typing
    };

    const navigate = useNavigate();
    const goToLogin = () => {
        navigate('/log-in');
    };

    const [success, setSuccess] = useState(false);

    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Regex for password validation: at least one letter, one number, and minimum length 6
    const passwordRegex =/^.{6,}$/;

    const handleSubmit = async (e) => {
        e.preventDefault();

        let isValid = true;

        if (username === '') {
            setUsernameError('Username required');
            isValid = false;
        }
        if (password === '') {
            setPasswordError('Password required');
            isValid = false;
        }
        if (confirmPassword === '') {
            setConfirmPasswordError('Confirm Password');
            isValid = false;
        } else if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            isValid = false;
        }
        if (nickName === '') {
            setNickNameError('Nickname required');
            isValid = false;
        }
        if (!emailRegex.test(email)) {
            setEmailError('Invalid email format');
            isValid = false;
        } else {
            setEmailError('');
        }

        if (username.length < 5) { // Minimum 5 characters for username
            setUsernameError('Username must be at least 5 characters');
            isValid = false;
        } else {
            setUsernameError('');
        }
    
        if (!passwordRegex.test(password)) {
            setPasswordError('Password must be at least 6 characters, with at least one letter and one number');
            isValid = false;
        } else {
            setPasswordError('');
        }

        if (nickName.length < 5) { // Minimum 5 characters for username
            setNickNameError('Profile Name must be at least 5 characters');
            isValid = false;
        } else {
            setNickNameError('');
        }
    
       


        if (isValid) {
            setShowOverlay(true)
            const accountData = {
                userName: username,
                emailAddress: email,
                userPassword: password,
                nickName: nickName
            };

            const phonePostUrl = `${config.apiHost}/api/v1/Memers/newMemer`;

            try {
                const response = await fetch(phonePostUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(accountData)
                });

                if (response.ok) {
                    setSuccess(true);
                    setShowOverlay(false)
                } else {
                    console.error('Registration failed', accountData);
                    setSuccess(false);
                    setShowOverlay(false)
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    return (
        <div style={styles.container}>
               {showOverlay && (
                    <div>
                        <OverlayMessage message={"Please wait a second as we submit your details . . ."} />
                    </div>
                )}
            <div style={styles.formContainer}>
                {error && <p style={styles.errorText}>{error}</p>}
                <Typography variant="h4" component="h1" style={styles.title}>Sign Up</Typography>
                
                <TextField
                    label="Username"
                    variant="filled"
                    autoComplete="off"
                    focused
                    inputProps={{ style: styles.textFieldInput }}
                    onChange={handleSetUsername}
                    error={Boolean(usernameError)}
                    helperText={usernameError}
                    style={styles.textField}
                />

                <TextField
                    label="Handle/Profile Name"
                    variant="filled"
                    focused
                    autoComplete="off"
                    inputProps={{ style: styles.textFieldInput }}
                    onChange={handleSetNickname}
                    error={Boolean(nickNameError)}
                    helperText={nickNameError}
                    style={styles.textField}
                />

                <TextField
                    label="Email"
                    variant="filled"
                    focused
                    autoComplete="off"
                    inputProps={{ style: styles.textFieldInput }}
                    onChange={handleSetEmail}
                    error={Boolean(emailError)}
                    helperText={emailError}
                    style={styles.textField}
                />

                <TextField
                    label="Password"
                    variant="filled"
                    focused
                    autoComplete="off"
                    inputProps={{ style: styles.textFieldInput }}
                    onChange={handleSetPassword}
                    error={Boolean(passwordError)}
                    helperText={passwordError}
                    type="password"
                    style={styles.textField}
                />

                <TextField
                    label="Confirm Password"
                    variant="filled"
                    focused
                    autoComplete="off"
                    inputProps={{ style: styles.textFieldInput }}
                    onChange={handleConfirmPasswordChange}
                    error={Boolean(confirmPasswordError)}
                    helperText={confirmPasswordError}
                    type="password"
                    style={styles.textField}
                />

                <div style={{textAlign:"center",justifyContent:"center",alignItems:"center",display:"flex",gap:"20px"}}>
                <Button onClick={handleSubmit} style={styles.submitButton} startIcon={<InputIcon style={{color:"white"}} />}>Submit</Button>
                <Button onClick={goToLogin} style={styles.backButton} startIcon={<LoginIcon style={{color:"white"}} />}>Back to Log-In</Button>
                </div>
            </div>

            {success && (
                <div style={styles.successContainer}>
                    <p style={styles.successText}>Success! Your account has been created.</p>
                    
                    <Link to="/log-in" style={styles.link}>Proceed to Log In to MemesKenya.</Link>
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'black',
        padding: '20px'
    },
    formContainer: {
        width: '100%',
        maxWidth: '600px',
        padding: '20px',
        backgroundColor: '#272727',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center' 
    },
    title: {
        marginBottom: '20px'
    },
    textField: {
        marginBottom: '20px',
        width: '100%'
    },
    textFieldInput: {
        color: 'white'
    },
    submitButton: {

        backgroundColor: '#3C97C7',
        color: '#272727',
        borderColor: '#3C97C7'
    },
    backButton: {
    
        backgroundColor: '#E74032',
        color: '#272727',
        borderColor: '#E74032'
    },
    errorText: {
        color: 'red',
        marginBottom: '10px'
    },
    successContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.95)',
        display: 'flex',
        flexDirection:"column",
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        textAlign: 'center'
    },
    successText: {
        color: 'white',
        fontSize: '24px',
        marginBottom: '20px'
    },
    link: {
        color: '#3C97C7',
        fontSize: '18px',
        textDecoration: 'none'
    }
};
