import { Button, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import "../styles/SignUp.css"
import InputIcon from '@mui/icons-material/Input';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from "react-router-dom";
import config from "../scripts/config";
import OverlayMessage from "../scripts/OverlayMessage";
import { useMediaQuery, useTheme } from '@mui/material';
export default function SignUp() {
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showOverlay, setShowOverlay] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleSetEmail = (event) => {
        const value = event.target.value;
            // Remove spaces from the input value
            const noSpacesValue = value.replace(/\s/g, '');
        setEmail(noSpacesValue);
        setEmailError(''); // Clear error when user starts typing
    };

    const navigate = useNavigate();
    const goToLogin = () => {
        navigate('/log-in');
    };

    const resetSuccess = () => {
        setSuccess(false)
        setFailed(false)
    }

    //checks/manages successful and failed signUp
    const [success, setSuccess] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [message, setMessage] = useState('Ooops!! Ran into a problem.')
    const handleSubmit = async (e) => {
        setSuccess(false);
        e.preventDefault();

        let isValid = true;
        if (email === '') {
            setEmailError('Email required');
            isValid = false;
        }

        if (isValid) {
            setShowOverlay(true)
            const accountData = {
                userName: "",
                email: email,
                password: ""
            };


            const phonePostUrl = `${config.apiHost}/api/v1/password/initiate-reset`;

            try {
                const response = await fetch(phonePostUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(accountData)
                });

                if (response.ok) {
                    setShowOverlay(false)
                    // The POST request(signUp) was successful, handle the response as needed
                    console.log('Registration successful');
                    setSuccess(true);

                } else if (response.status === 404) { // Handling NO_CONTENT (204) error
                    setShowOverlay(false);
                    setMessage('No user exists with the provided email.'); // Custom message for 204 error
                    setSuccess(false);
                    setFailed(true);
                } else {
                    // Handle error response here
                    console.error('User Not Found');
                    console.log("gbfgb ", response.statusText);
                    setSuccess(false);
                    setShowOverlay(false)
                    setFailed(true)
                }
            } catch (error) {
                // Handle network or other errors here
                console.error('Error:', error);
                setShowOverlay(false)
                setFailed(true)
            }
            // await new Promise((resolve) => setTimeout(resolve, 2000)); // Delay for 2 seconds
        }
    };

    const styles =
    {
        color: '#3C97C7', textAlign: 'center', marginTop: '5vh', marginLeft: "10vw", fontSize: "20px", textWrap: "wrap", maxWidth: "80vw",
        cursor: "pointer"
    }



    return (
        <>
            {showOverlay && (
                <div>
                    <OverlayMessage message={"Please wait a second as we submit your request and verify . . ."} />
                </div>
            )}
            <div style={{
                display: "flex",
                flexDirection: "column", // Stack children vertically
                justifyContent: "center", // Center children vertically within the container
                alignItems: "center", // Center children horizontally within the container
                textAlign: "center", // Center text within the children
                position: "absolute", // Ensure the container is positioned relative to its nearest positioned ancestor
                top: "50%", // Push the container down from the top by 50% of the parent height
                left: "50%", // Center the container horizontally
                transform: "translate(-50%, -50%)", // Adjust the container's position by 50% of its own width and height
            }}>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <div style={{ justifyContent: "center", alignItems: "center", textAlign: "center", marginBottom: "2%" }}>
                    <Typography style={{ fontSize: "30px" }}>Password  <span style={{ fontSize: "px" }}>Reset</span></Typography>
                </div>
                <TextField
                    className="textField"
                    label="Email" variant="filled"
                    inputProps={{ style: { color: "whitesmoke" } }}
                    onChange={handleSetEmail}
                    focused
                    style={{ marginTop: "35px", width: isSmallScreen ? "250px" : "380px" }}
                    required
                />
                {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", padding: "12px", gap: "20px" }}>
                    <Button onClick={handleSubmit} style={{ border: "white .5px solid", backgroundColor: "white", width: isSmallScreen ? "250px" : "380px", color: 'black', height: "40px" }} startIcon={<InputIcon style={{ color: "black" }} />}>Submit Reset Request</Button>
                    <Button onClick={goToLogin} style={{ border: "#E74032 .5px solid", backgroundColor: "#E74032", width: isSmallScreen ? "250px" : "380px", color: 'white', height: "40px" }} startIcon={<LoginIcon style={{ color: "white" }} />}>Back to Log-In</Button>
                </div>
                {/* <Button style={{ border: "#E74032 .5px solid", marginBottom: "15%", backgroundColor: "#E74032", color: 'white' }} startIcon={<GoogleIcon style={{ color: "white" }} />}>Sign Up using Google</Button> */}

            </div>
            {
                success && (
                    <div style={{ width: "100vw", height: "150vh", backgroundColor: "black", position: "absolute", top: 0, left: 0, zIndex: 999, justifyContent: "center", textAlign: "center", alignItems: "center" }}>
                        <p style={{ color: 'white', textAlign: 'center', marginTop: '30vh', marginLeft: "10vw", fontSize: "30px", textWrap: "wrap", maxWidth: "80vw", lineBreak: "auto" }}>Success! A reset link has been sent to:</p>
                        <p style={styles}>{email}</p>
                        <p style={styles} onClick={resetSuccess}>Close</p>
                    </div>
                )
            }
            {
                failed && (
                    <div style={{ width: "100vw", height: "150vh", backgroundColor: "black", position: "absolute", top: 0, left: 0, zIndex: 999, justifyContent: "center", textAlign: "center", alignItems: "center" }}>
                        <p style={{ color: 'red', textAlign: 'center', marginTop: '30vh', marginLeft: "10vw", fontSize: "30px", textWrap: "wrap", maxWidth: "80vw", lineBreak: "auto" }}>{message}</p>
                        <p style={styles}>{email}</p>
                        <p style={styles} onClick={resetSuccess}>Close</p>
                    </div>
                )
            }
        </>
    )
}