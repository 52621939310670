import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, useMediaQuery, useTheme } from "@mui/material";

export default function WelcomeBanner({ name , setShow }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const close = () =>{
        setShow(false);
        localStorage.setItem("showWelcome",false)
    }
    return (
        <div>
            <Card sx={{ maxWidth: 345 }} style={{ height: isSmallScreen ? "70vh" : "500px", width: isSmallScreen ? "90vw" : "55vw" }}>
                <CardMedia
                    style={{ height: isSmallScreen ? "200px" : "230px" }}
                    image={require('../visuals/mk.webp')}
                    title="MemesKenya"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        MemesKenya
                    </Typography>
                    <Box
                        
                        sx={{
                            maxHeight: "120px", // Adjust the height as needed
                            overflowY: "auto",
                            padding: "10px",
                            backgroundColor: "white", // Optional: background styling
                            borderRadius: "4px", // Optional: rounded corners
                        }}
                    >
                        <Typography variant="body2" color="#272727" style={{ fontSize: "14px" }}>
                            Hi 🎉<span style={{ fontSize: "14px" }}>{name}</span>🎉, welcome to MemesKenya, the largest platform of memes that you
                            can relate with. We take pride in humor, and so do you if you reached
                            here. Just a polite request that you please share some memes you think we might need to check out. We thrive in sharing laughter, creativity, and joy through the
                            best memes from across the country. Enjoy exploring our vast collection,
                            share with fellow meme lovers, and have a good laugh. Dive into the
                            fun and let the memes begin!
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions>
                    <Button onClick={close} size="small">Continue</Button>
                </CardActions>
            </Card>
        </div>
    );
}
