import { Button, Typography, useMediaQuery } from "@mui/material";
import TextField from '@mui/material/TextField';
import React, { useState } from "react";
import "../styles/SignUp.css"
import GoogleIcon from '@mui/icons-material/Google';
import InputIcon from '@mui/icons-material/Input';
import LoginIcon from '@mui/icons-material/Login';
import { Link, useNavigate } from "react-router-dom";
import { InitializeSession } from "./LogInPage";
import { useDispatch } from "react-redux";
import config from "../scripts/config";

export default function SignUp() {
    const [error, setError] = useState('');

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');

    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const dispatch = useDispatch();

    const handleSetUsername = (event) => {
        const value = event.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setUsername(noSpacesValue);
        setUsernameError(''); // Clear error when user starts typing
    };

    const handleSetPassword = (event) => {
        const value = event.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setPassword(noSpacesValue);
        setPasswordError(''); // Clear error when user starts typing
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setConfirmPassword(noSpacesValue);
        setConfirmPasswordError(''); // Clear error when user starts typing
    };


    const handleSetEmail = (event) => {
        const value = event.target.value;
        // Remove spaces from the input value
        const noSpacesValue = value.replace(/\s/g, '');
        setEmail(noSpacesValue);
        setEmailError(''); // Clear error when user starts typing
    };

    const navigate = useNavigate();
    const goToLogin = () => {
        navigate('/log-in');
    };


    //checks/manages successful and failed signUp
    const [success, setSuccess] = React.useState(false);

    const handleSubmit = async (e) => {
        setSuccess(false);
        e.preventDefault();

        let isValid = true;

        if (username === '') {
            setUsernameError('Username required');
            isValid = false;
        }
        if (password === '') {
            setPasswordError('Password required');
            isValid = false;
        }
        if (confirmPassword === '') {
            setConfirmPasswordError('Confirm Password');
            isValid = false;
        } else if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            isValid = false;
        }
        if (email === '') {
            setEmailError('Email required');
            isValid = false;
        }

        if (isValid) {
            if (password === confirmPassword) {
                // Passwords match, set the password
                setError('');
                // Logic to handle setting the password (e.g., API call)
                console.log('Password set:', password);
            } else {
                // Passwords do not match, show error message
                setError('Passwords do not match');
                return;
            }

            const accountData = {
                userName: username,
                email: email,
                password: password
            };

            const getToken = () => {
                const params = new URLSearchParams(window.location.search);
                return params.get('access');
            };
            const token = getToken();
            const phonePostUrl = `${config.apiHost}/api/v1/password/reset`;

            try {
                const response = await fetch(phonePostUrl, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(accountData)
                });

                if (response.ok) {
                    // The POST request(signUp) was successful, handle the response as needed
                    console.log('Reset successful');
                    // The POST request was successful, handle the response as needed
                    const data = await response.json();
                    console.log('Registration successful', data);
                    localStorage.setItem("loaderShown", "false");
                    InitializeSession(data, dispatch);
                    navigate('/home');
                } else {
                    // Handle error response here
                    console.error('Registration failed', accountData);
                    setSuccess(false);
                }
            } catch (error) {
                // Handle network or other errors here
                console.error('Error:', error);
            }
            // await new Promise((resolve) => setTimeout(resolve, 2000)); // Delay for 2 seconds
        }
    };


    return (
        <>
            <div id="signUpContainer">
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <div style={{ justifyContent: "center", alignItems: "center", textAlign: "center", marginBottom: "2%" }}>
                    <Typography style={{ fontSize: "22px" }}>Complete password  <span style={{ fontSize: "22px" }}>Reset</span></Typography>
                </div>
                {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>}
                <TextField
                    className="textField"
                    label="Username" variant="filled"
                    focused
                    inputProps={{ style: { color: "whitesmoke" } }}
                    onChange={handleSetUsername}
                    style={{ marginTop: "35px" }}
                    required
                />

                <TextField
                    className="textField"
                    label="Email" variant="filled"
                    inputProps={{ style: { color: "whitesmoke" } }}
                    onChange={handleSetEmail}
                    focused
                    style={{ marginTop: "35px" }}
                    required
                />
                
                {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}

                <TextField
                    className="textField"
                    label="Password" variant="filled"
                    inputProps={{ style: { color: "whitesmoke" } }}
                    onChange={handleSetPassword}
                    focused
                    style={{ marginTop: "35px" }}
                    required
                />
                {confirmPasswordError && <p style={{ color: 'red' }}>{confirmPasswordError}</p>}

                <TextField
                    className="textField"
                    label="Confirm New Password" variant="filled"
                    inputProps={{ style: { color: "whitesmoke" } }}
                    onChange={handleConfirmPasswordChange}
                    focused
                    style={{ marginTop: "35px" }}
                    required
                />
                <Button onClick={handleSubmit} style={{ border: "white .5px solid", marginTop: "5%", marginBottom: "5%", backgroundColor: "white", color: 'black' }} startIcon={<InputIcon style={{ color: "black" }} />}>Submit</Button>
                <Button onClick={goToLogin} style={{ border: "#E74032 .5px solid", marginBottom: "5%", backgroundColor: "#E74032", color: 'white' }} startIcon={<LoginIcon style={{ color: "white" }} />}>Back to Log-In</Button>

            </div>
        </>
    )
}