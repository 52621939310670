import React, { } from 'react';
import { AvatarGroup } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import '../styles/Notification.css'
import SouthOutlinedIcon from '@mui/icons-material/SouthOutlined';
const NotificationComponent = ({ notifications, action, notificationMessage }) => {

    // Safely access the first notification and get its nickName
    const firstNickName = notifications.length > 0 ? notifications[0].nickName : "No notifications";


    return (
        <div>
            {notifications.length > 5 ? (
                <div className='notification-content-many' id='avatars' onClick={action}>
                    <AvatarGroup max={4} total={notifications.length}>
                        <SouthOutlinedIcon style={{marginRight:"10px",marginTop:"7%"}}/>
                        <Avatar alt={notifications[0].nickName} src={`https://www.memeskenya.com${notifications[0].imageUrl}`} />
                        <Avatar alt={notifications[1].nickName} src={`https://www.memeskenya.com${notifications[1].imageUrl}`} />
                        <Avatar alt={notifications[2].nickName} src={`https://www.memeskenya.com${notifications[2].imageUrl}`} />
                        <Avatar alt={notifications[3].nickName} src={`https://www.memeskenya.com${notifications[3].imageUrl}`} />
                    </AvatarGroup>

                </div>
            ) : (
                <div className='notification-content' id='lessThanFiveNotification' 
                // onClick={action}
                >
                    {/* <Avatar style={{ backgroundColor: "#272727", height: "50px", width: "50px" }} alt={firstNickName} src={`https://www.memeskenya.com${notifications[0].imageUrl}`} /> */}
                    <SouthOutlinedIcon style={{marginRight:"10px",marginTop:"0%"}}/>
                    <p>{notificationMessage}</p>
                </div>
            )}
        </div>
    );
};

export default NotificationComponent;
