// OverlayMessage.js
import React from 'react';
import '../styles/OverlayUnsigned.css';
import { List, ListItem, ListItemText, Typography } from '@mui/material';


const OverlayMessage = ({ message, onClose }) => {
    return (
        <div className="overlay">
            <div className="message-box">
                <p>{message}</p>
                <div className="scrollable-content">
                    <List>
                        
                        <ListItem>
                            <ListItemText
                                primary={<Typography variant="body1" sx={{ color: '#45ADE4' }}>Like</Typography>}
                                secondary={<Typography variant="body2" sx={{ color: 'rgb(196, 205, 213)', fontSize: "14px" }}>Upvote a favorite meme as an appreciation to the poster.</Typography>}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={<Typography variant="body1" sx={{ color: '#45ADE4' }}>Download</Typography>}
                                secondary={<Typography variant="body2" sx={{ color: 'rgb(196, 205, 213)', fontSize: "14px" }}>Share a meme with others on your favorite social media platforms.</Typography>}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={<Typography variant="body1" sx={{ color: '#45ADE4' }}>Post</Typography>}
                                secondary={<Typography variant="body2" sx={{ color: 'rgb(196, 205, 213)', fontSize: "14px" }}>Share a favorite meme with fellow memers under a preferred tag.</Typography>}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={<Typography variant="body1" sx={{ color: '#45ADE4' }}>Tags</Typography>}
                                secondary={<Typography variant="body2" sx={{ color: 'rgb(196, 205, 213)', fontSize: "14px" }}>Access memes under our designated tags, annotated with the help of our users e.g. Politics, Science and Tech, etc.</Typography>}
                            />
                        </ListItem>
                    </List>
                </div>
                <button id='button' onClick={onClose}>Close</button>

            </div>
        </div>
    );
};

export default OverlayMessage;
