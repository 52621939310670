import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../styles/Home.css'
import React, { useState } from "react";
import Badge from '@mui/material/Badge';
import SideMenu from "./SideMenu"
import SideMenuWeb from "./SideMenuWeb"
import CreatePost from "./CreatePost";
import { Fade, Grow, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import Box from '@mui/material/Box';
import M from "../visuals/M_Dark.gif";
import checkJWTValidity from "../scripts/CheckJWTExpiry";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { useTheme } from '@material-ui/core'
import NotificationComponent from "../Notification/Notification";
import config from "../scripts/config";
import SpeedDial from '@mui/material/SpeedDial';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AutoLoadOnScroll from "./AutoLoadOnScroll";
import WelcomeBanner from "./WelcomeBanner";
import MemeskenyaMessage from "../Notification/Memeskenyamessage";
import TopMemerOfTheWeek from "./TopMemerOfTheWeek ";
import PhoneViewTopMemer from "../Notification/PhoneViewTopMeme";
// Get the current URL
async function PreLoadOperations(setLoading) {
    const navigate = useNavigate();

    useEffect(() => {
        const checkSession = async () => {
            // Check token from localStorage
            const oauth = localStorage.getItem("oauth");
            // After checking, navigate or perform other operations
            if (!checkJWTValidity(oauth)) {
                navigate("/log-in");
            }
            // Check if the loader has been shown
            const loaderShown = localStorage.getItem("loaderShown");
            // If loader hasn't been shown, show it for 3 seconds
            if (!loaderShown) {
                setLoading(true);
                await new Promise(resolve => setTimeout(resolve, 3000));
                setLoading(false);

                // Set the flag in localStorage to indicate the loader has been shown
                localStorage.setItem("loaderShown", "true");
            }
            setLoading(false)


        };

        checkSession();
    }, [navigate, setLoading]);
}



// the post
function Post() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const data = useSelector((state) => state.person);
    const [loading, setLoading] = useState(true);
    const storedUser = useSelector((state) => state.person.name);
    const categories = ['All Memes', 'My Memes', 'General', 'Politics and Current Events', 'Entertainment', 'Science & Technology', 'Education and Learning', 'Humor and Satire', 'Media',
        "History and Nostalgia", 'Health and Wellness', 'Relationships and Personal Life', 'Celebrities and Showbiz', 'Sports and Recreation'
    ]

    // stats
    const [localLikes, setLocalLikes] = React.useState([]);
    const [localUnLikes, setLocalUnLikes] = React.useState([]);
    const [localDownloads, setLocalDownloads] = React.useState([]);
    const [showNotification, setShowNotification] = React.useState(false);
    const [notificationMessage, setNotificationMessage] = React.useState("New memes posted");
    const [views, setViews] = useState([]);
    const [latestviewedMemePosition, setLatestviewedMemePosition] = useState(0);
    const authToken = localStorage.getItem("oauth");
    const [notifications, setNotifications] = useState([]);
    const [memeskenyaMessages, setMemeskenyaMessages] = useState([]);
    const [loadedPosts, setLoadedPosts] = useState([]);
    const memerId = localStorage.getItem("memerId");
    const [showWelcome, setShowWelcome] = useState(!localStorage.getItem("showWelcome"))

    const sendUpdate = async (type, list) => {
        const url = `${config.apiHost}/api/v1/posts/batchUpdate${type.charAt(0).toUpperCase() + type.slice(1)}?increment=1&userId=${localStorage.getItem("memerId")}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(list) // Directly send the list of UUIDs
            });
            if (!response.ok) {
                throw new Error(`Server error: ${response.statusText} `);
            }
        } catch (error) {
            console.error(`Error updating ${type}:`, error);
        }
    };


    const batchUpdate = async () => {
        if (localLikes.length > 0) {
            await sendUpdate('likes', localLikes);
            setLocalLikes([]);
        }

        if (localDownloads.length > 0) {
            await sendUpdate('downloads', localDownloads);
            setLocalDownloads([]);
        }

        if (localUnLikes.length > 0) {
            await sendUpdate('unlikes', localUnLikes);
            setLocalUnLikes([]);
        }
        if (views.length > 0) {
            await sendUpdate('views', views);
            setViews([]);
        }
    };

    const handleNotificationClick = () => {
        // Use the existing notifications data
        setLoadedPosts((prevLoadedPosts) => {
            const allPosts = [...prevLoadedPosts, ...notifications];
            const uniquePosts = allPosts.reduce((acc, current) => {
                const x = acc.find(item => item.postId === current.postId);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            return uniquePosts;
        });
        setNotifications([]);
        setShowNotification(false); // Hide the notification after clicking
        // setShowTags(false);
    };


    useEffect(() => {
        fetch(`${config.apiHost}/api/v1/messages/getNewMessages?userId=${memerId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                if (!data) {
                    // Handle the case where data is null or has zero length
                    console.log("No data available");
                    return;
                }
                setMemeskenyaMessages(data);
            });
    }, [memerId]);

    const removeMemesKenyaMessage = (messagetoRemove) => {
        setMemeskenyaMessages(prevMessages =>
            prevMessages.filter(message => message !== messagetoRemove)
        );
    };


    useEffect(() => {
        const socket = new SockJS(`${config.apiHost}/ws`);
        const stompClient = new Client({
            webSocketFactory: () => socket,
            debug: () => { },  // Disable debugging output
            reconnectDelay: 5 * 60 * 1000, // Automatically reconnect after 5 seconds if the connection is lost
            onConnect: () => {
                stompClient.subscribe('/topic/notifications', (message) => {
                    const newNotifications = JSON.parse(message.body);

                    const filteredNotifications = newNotifications.filter(
                        notification => notification.memerId !== memerId
                    );

                    if (filteredNotifications.length > 0) {
                        setNotifications(prevNotifications => {
                            const updatedNotifications = [...prevNotifications, ...filteredNotifications];

                            setLoadedPosts(prevLoadedPosts => {
                                const allPosts = [...prevLoadedPosts, ...updatedNotifications];
                                const uniquePosts = allPosts.reduce((acc, current) => {
                                    const x = acc.find(item => item.postId === current.postId);
                                    return !x ? acc.concat([current]) : acc;
                                }, []);
                                return uniquePosts;
                            });

                            return updatedNotifications;
                        });

                        setShowNotification(true);

                        setTimeout(() => {
                            setShowNotification(false);
                            // handleNotificationClick();
                        }, 15 * 1000);
                    } else {
                        setShowNotification(false);
                    }
                });
            },
            onStompError: (frame) => {
                console.error(`Broker reported error: ${frame.headers['message']}`);
                // console.error(`Additional details: ${frame.body}`);
            }
        });

        stompClient.activate(); // Initiate the connection

        return () => {
            stompClient.deactivate(); // Clean up the connection
        };
    }, [memerId, loadedPosts]);



    React.useEffect(() => {

        const handleBeforeUnload = async () => {
            // Perform the batch update before the page unloads
            await batchUpdate();
        };

        const intervalId = setInterval(async () => {
            await batchUpdate();
        }, 25 * 60 * 1000); // Every 25 minutes

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            clearInterval(intervalId);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [localDownloads, localLikes, localUnLikes]);
    //preload
    PreLoadOperations(setLoading);

    // show side menu pop up variables
    const [showSidePopup, setSideShowPopup] = useState(false);

    //shows the side menu popu
    const handleSideButtonClick = () => {
        setSideShowPopup(true);
    };

    //closes the side menu show
    const handleSideClosePopup = () => {
        setSideShowPopup(false);
    };


    // show pop up variables
    const [showPopup, setShowPopup] = useState(false);

    //shows the post popup
    const handleButtonClick = () => {
        setShowPopup(true);
    };

    //closes the post popup show
    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const updatePosition = (memerId, position) => {
        fetch(`${config.apiHost}/api/v1/messages/updateMessagePosition?userId=${memerId}&newPosition=${position}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Position updated:', data);
                // Handle further logic like removing the message from the UI
            })
            .catch(error => {
                console.error('Error updating position:', error);
            });
    };


    const menuStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        textAlign: "center",
        flexDirection: "row",
        gap: isSmallScreen ? "10%" : "10%",
        padding: isSmallScreen ? "0 7%" : "0 5%",
        maxWidth: "1200px",
        width: "100%",
        position: "fixed",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 9,
        // height: "90px"
    };

    const isLargeScreen = useMediaQuery('(min-width:2000px)');
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 represents Sunday

    return (
        <div>
            {showWelcome && (
                <div className="modal-overlay">
                    <WelcomeBanner name={storedUser} setShow={setShowWelcome} />
                </div>
            )}
            {loading ? (
                <div style={{ backgroundColor: "black", width: "100vw", height: "100vh", alignItems: "center", justifyContent: "center", display: "flex" }}>
                    <img alt="loader for mememskenya" src={M} id="preLoader" />
                </div>
            ) : (

                <div style={{ backgroundColor: "transparent" }}>
                    <div style={menuStyle}>
                        <MenuRoundedIcon
                            onClick={handleSideButtonClick}
                            fontSize="large"
                            style={{ visibility: isSmallScreen ? "visible" : "hidden" }}
                        />

                        {showSidePopup && (

                            <Fade in={showSidePopup} timeout={{ enter: 1000 }}>
                                <div className="menu-overlay">

                                    <SideMenu avatar={data.image} name={data.name} closeAction={handleSideClosePopup}
                                        postAction={handleButtonClick}
                                    />
                                </div>
                            </Fade>
                        )}

                        <p id="title">Memes<span id="titleSpan">Kenya</span></p>
                        <Badge badgeContent={12} color="error" style={{ visibility: "hidden" }}>
                            <FontAwesomeIcon icon={faBell} size="1x" color="white" style={{ visibility: "hidden" }} />
                        </Badge>

                    </div>
                    <div style={{ visibility: isSmallScreen ? "hidden" : "visible", width: '100px' }}>
                        {/* the side menu for web */}
                        <SideMenuWeb avatar={data.image} nickName={data.name}
                            postAction={handleButtonClick}
                        />
                    </div>

                    <div style={{ backgroundColor: "transparent" }}>
                        {showPopup && (
                            <Grow
                                in={showPopup}
                                style={{ transformOrigin: '0 0 0 0' }}
                                {...(showPopup ? { timeout: 1000 } : {})}
                            >
                                <div className="modal-overlay">
                                    <div>
                                        <div style={{ marginTop: "20%" }}>
                                            <CreatePost
                                                closeAction={handleClosePopup}
                                                image={data.image}
                                                userId={data.memerId}
                                                queryString={data.authToken}
                                                nickName={data.name}
                                                setLoadedPosts={setLoadedPosts}
                                                categories={categories}
                                                setNotificationMessage={setNotificationMessage}
                                            // styles the top most container div in the create post 
                                            //custom component

                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grow>
                        )
                        }
                        {showNotification && (<div id="notification">
                            <NotificationComponent notifications={notifications} notificationMessage={notificationMessage} action={handleNotificationClick} />
                        </div>)}
                        <div
                            // id="contentDiv"
                            style={{ marginTop: "20%" }}
                        >

                            <AutoLoadOnScroll
                                localDownloads={localDownloads}
                                setLocalDownloads={setLocalDownloads}
                                localLikes={localLikes}
                                setLocalLikes={setLocalLikes}
                                batchUpdate={batchUpdate}
                                setLocalUnLikes={setLocalUnLikes}
                                setViews={setViews}
                                views={views}
                                notifications={notifications}
                                setLatestviewedMemePosition={setLatestviewedMemePosition}
                                latestviewedMemePosition={latestviewedMemePosition}
                                loadedPosts={loadedPosts}
                                setLoadedPosts={setLoadedPosts}
                                isSmallScreen={isSmallScreen}
                            />

                            <div style={{ position: "fixed", top: isSmallScreen ? "80%" : "80%", left: isSmallScreen ? "75%" : "60%", visibility: isSmallScreen ? "visible" : "hidden" }}>
                                <BasicSpeedDial />
                            </div>
                        </div>
                        {!isSmallScreen && (
                            <div style={{ position: "fixed", right: "5%", top: "20%", marginTop: "1%" }}>
                                <Typography style={{ color: "white", fontSize: "30px", marginLeft: isLargeScreen ? "25%" : "0%" }}>Notifications</Typography>
                                <div>
                                    {dayOfWeek === 0 &&  <TopMemerOfTheWeek />}
                                </div>
                                <div style={{
                                    height: dayOfWeek !== 0 ? "75vh" : "7vh", width: "300px", backgroundColor: "transparent", overflowY: "auto", padding: "5px", marginTop: "2%", scrollbarWidth: "thin", // For Firefox
                                    scrollbarColor: "grey transparent"
                                }}>
                                    {memeskenyaMessages.length > 0 ? (
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            {memeskenyaMessages.map((message, index) => (
                                                <MemeskenyaMessage key={index} messageTitle={message.title} updatePosition={updatePosition} position={message.id} memerId={memerId} parentMessage={message}
                                                    removeMessage={removeMemesKenyaMessage} />
                                            ))}
                                        </div>
                                    ) : (
                                        <Typography style={{ fontSize: "35px", textAlign: "center", color: "grey", margin: "auto" }}>
                                            No notifications
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        )}

                        <PhoneViewTopMemer />
                    </div>
                </div>
            )}
        </div>
    )


    function BasicSpeedDial() {
        const [open, setOpen] = React.useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        // Your custom action when SpeedDial is clicked
        const handleSpeedDialClick = () => {
            setShowPopup(true); // or any other action you want to trigger
        };
        return (
            <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    style={{ justifyContent: "center" }}
                    icon={<PostAddIcon />}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                    onClick={handleSpeedDialClick} // Handle click on the SpeedDial
                />
            </Box>
        );
    }

}

export default Post;