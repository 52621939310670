import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Typography } from "@mui/material";
import React from "react";
import aujusi from "../visuals/jusifrut.jpg"
import "../styles/Profiles.css"


// this object returns a chat object instance
const SearchOBJ = (props) => {
    return (
        // search content
        <div style={{ marginTop: "2%", display: "flex", justifyContent: "space-evenly", alignItems: "center", paddingLeft: "4%", paddingRight: "8%" }}>
            {/* searched avatar */}
            <div style={{marginRight:"2%"}}>
                <Avatar alt="Search" src={aujusi} style={{
                    width: "55px",
                    height: "55px"
                }} />
            </div>
            <div style={{ display: "block", paddingTop: "3%" }}>
                <Typography style={{color:"#45ADE4"}}>
                    {props.nickName}
                </Typography>
                <p style={{ marginTop: "-2%", marginLeft: "3%",color:"grey"}}>
                    {props.description}
                </p>
            </div>
            <button
                style={{
                    marginTop: "6%",
                    marginLeft: "2%",
                    width: "70%",
                    height: "30px",
                    backgroundColor:props.backgroundColor,
                    borderRadius:"4px",
                    border:"1px solid "+props.border
                }}
                className="searchesBtn">
                { props.followStatus === "true" ? "Following" : "Follow"}
            </button>
        </div>
    )

}

export default function () {
    return (
        <div>
            {/* navigation div */}
            <div style={{position:"fixed",backgroundColor:"black",zIndex:"999",width:"100%",height:"13%",marginTop:"-4%"
        }}>
                <FontAwesomeIcon icon={faCircleArrowLeft} size="2x" style={{
                    marginTop: "10%",
                    marginLeft: "3%"
                                    }} />
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <SearchOBJ followStatus="true" description="The ignorant shall never get tounderstand themthings here" nickName="Muootoo" backgroundColor="transparent" border="#45ADE4"/>
            <SearchOBJ followStatus="false"description="The ignorant shall never get tounderstand themthings here" nickName="Aujusi" backgroundColor="#45ADE4" border="transparent"/>
            <SearchOBJ followStatus="true" description="The ignorant shall never get tounderstand themthings here" nickName="Vawu-lense" backgroundColor="transparent" border="#45ADE4"/>
            <SearchOBJ followStatus="false"description="The ignorant shall never get tounderstand themthings here" nickName="Vayolence" backgroundColor="#45ADE4" border="transparent"/>
            <SearchOBJ followStatus="true" description="The ignorant shall never get tounderstand themthings here" nickName="ShiseaA" backgroundColor="transparent" border="#45ADE4"/>
            <SearchOBJ followStatus="true" description="The ignorant shall never get tounderstand themthings here" nickName="ShiseaA" backgroundColor="transparent" border="#45ADE4"/>
            <SearchOBJ followStatus="true" description="The ignorant shall never get tounderstand themthings here" nickName="ShiseaA" backgroundColor="transparent" border="#45ADE4"/>
            <SearchOBJ followStatus="false" description="The ignorant shall never get tounderstand themthings here" nickName="ShiseaA" backgroundColor="#45ADE4" border="transparent"/>
            <SearchOBJ followStatus="true" description="The ignorant shall never get tounderstand themthings here" nickName="ShiseaA" backgroundColor="transparent" border="#45ADE4"/>
        </div>
    )
}