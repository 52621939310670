import {createSlice } from '@reduxjs/toolkit'

const personSlice = createSlice({
  name: 'user',
  initialState: {
    name: '',
    id: '',
    memerId:'',
    image:'',
    email:'',
    authToken: '',
    temporaryUsername:'',
    expiredPassword:''
  },
  reducers: {
    setDetails:(state,action)=>{
      const newDetails = action.payload;
      state.name=newDetails.name;
      state.id=newDetails.id;
      state.memerId=newDetails.memerId;
      state.email=newDetails.email;
      state.authToken = newDetails.authToken;
      state.image=newDetails.image;
      state.temporaryUsername=newDetails.temporaryUsername;
      state.expiredPassword=newDetails.expiredPassword;
    }
  }
})

 export const userActions = personSlice.actions;

 export default personSlice;