// PostEntity.js
import React, { useEffect, useRef, useState } from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { Typography, Box, useMediaQuery, useTheme, Skeleton, Badge } from '@mui/material';
import NumberFormatter from '../scripts/StatsFormatter';
import OverlayMessage from "./OverlayUnsigned";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VerifiedIcon from '@mui/icons-material/Verified';
import config from "../scripts/config";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function getDateOnly(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export default function PostEntity(props) {
    const theme = useTheme();
    const targetRef = useRef(null);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const canvasRef = React.useRef(null);
    let views = props.views;
    const memeId = localStorage.getItem("memerId");
    const senderId = props.senderId;
    const oauth = localStorage.getItem("oauth");
    const [likes, setLikes] = useState(props.likes);
    const [downloads, setDownloads] = useState(props.downloads);
    const postId = props.postId;
    const notsignedIn = props.notsignedIn;
    const [showOverlay, setShowOverlay] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [hasBeenViewed, setHasBeenViewed] = useState(false);

    const downloadOrFail = () =>{
        if (notsignedIn) {
            setShowOverlay(true);
            return; // Skip watermarking if not signed in
        }else{
            addWatermarkAndDownload();
        }

    }

    // Watermarking and Download Function
    const addWatermarkAndDownload = async () => {

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Fetch the image as a blob
        const response = await fetch(props.image, { mode: 'cors' });
        const blob = await response.blob();
        const image = new Image();

        image.src = URL.createObjectURL(blob); // Create an object URL from the blob
        image.crossOrigin = 'anonymous'; // Ensure cross-origin is set


        image.onload = () => {
          
            handleLocalUpdate('downloads'); // Update local download count if needed

            // Set canvas dimensions to match the image
            canvas.width = image.width;
            canvas.height = image.height;

            // Draw the image on the canvas
            ctx.drawImage(image, 0, 0);

            // Set up watermark styles
            const watermarkText = "Downloaded from memeskenya.com";
            ctx.font = '22px Arial';
            ctx.fillStyle = 'white';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            // Measure text dimensions
            const textMetrics = ctx.measureText(watermarkText);
            const textWidth = textMetrics.width;
            const textHeight = 20; // Approximate height based on font size

            // Background rectangle for watermark
            const rectX = (canvas.width - textWidth - 20) / 2;
            const rectY = canvas.height - textHeight - 20;
            const rectWidth = textWidth + 20;
            const rectHeight = textHeight + 10;

            ctx.fillStyle = 'rgba(0, 0, 0, 0.88)'; // Background color
            ctx.strokeStyle = 'black'; // Border color
            ctx.lineWidth = 2; // Border width

            // Draw the background with rounded corners
            ctx.beginPath();
            ctx.moveTo(rectX + 8, rectY);
            ctx.lineTo(rectX + rectWidth - 8, rectY);
            ctx.quadraticCurveTo(rectX + rectWidth, rectY, rectX + rectWidth, rectY + 8);
            ctx.lineTo(rectX + rectWidth, rectY + rectHeight - 8);
            ctx.quadraticCurveTo(rectX + rectWidth, rectY + rectHeight, rectX + rectWidth - 8, rectY + rectHeight);
            ctx.lineTo(rectX + 8, rectY + rectHeight);
            ctx.quadraticCurveTo(rectX, rectY + rectHeight, rectX, rectY + rectHeight - 8);
            ctx.lineTo(rectX, rectY + 8);
            ctx.quadraticCurveTo(rectX, rectY, rectX + 8, rectY);
            ctx.closePath();
            ctx.fill();
            ctx.stroke();

            // Draw the watermark text
            ctx.fillStyle = 'white';
            ctx.fillText(watermarkText, canvas.width / 2, rectY + rectHeight / 2);

            // Convert the canvas to a data URL and create a download link
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/jpeg');
            link.download = 'memeskenya-image.jpg';
            link.click();
        };

        // Handle image load error (optional)
        image.onerror = () => {
            console.error('Failed to load the image for watermarking');
        };
    };


    const handleLocalUpdate = (type) => {

        if (notsignedIn) {
            setShowOverlay(true);
            return;
        } else {
            if (type === 'likes') {
                if (!props.likedByMe) {
                    // Like the post by adding it to the array
                    props.setLocalLikes(prevLikes => [...prevLikes, postId]);
                    setLikes(prevLikes => prevLikes + 1);
                    props.setLikedPostIdsByMe(prevLikedPostIds => [...prevLikedPostIds, postId]);
                    props.setLocalUnLikes(prevUnLikes => prevUnLikes.filter(id => id !== postId));

                } else {
                    // unLike the post by remove it from the array
                    props.setLocalUnLikes(prevUnLikes => [...prevUnLikes, postId]);
                    props.setLocalLikes(prevLocalLikes => prevLocalLikes.filter(id => id !== postId));
                    setLikes(prevLikes => prevLikes - 1);
                    props.setLikedPostIdsByMe(prevLiked => prevLiked.filter(id => id !== postId));
                }

            }

            if (type === 'downloads') {
                props.setLocalDownloads(prevDownloads => [...prevDownloads, postId]);
                setDownloads(prevDownloads => prevDownloads + 1);
            }

            if (props.index) {
                props.batchUpdate();
            }
        }
    };


    const handleCloseOverlay = () => {
        setShowOverlay(false);
    };

    const updateOnLoad = () => {
        if (props.index) {
            props.batchUpdate();
        }
    }
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        if (memeId === senderId) {
            deletePost();
        }
        handleMenuClose();
    };

    const deletePost = async () => {
        const url = `${config.apiHost}/api/v1/posts/deletePost?postId=${postId}&userId=${memeId}`;
        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${oauth}`
                }
            });
            if (response.ok) {
                props.removePost(postId); // Remove post from the UI
            } else {
                throw new Error(`Failed to delete. Status: ${response.statusText} `);
            }
        } catch (error) {
            console.error(`Error updating `);
        }
    };



    useEffect(() => {
        const options = {
            root: null, // viewport
            rootMargin: '0px',
            threshold: 0.8, // 80% of the element must be visible
        };

        const callback = async (entries) => {
            entries.forEach(async (entry) => {
                if (entry.isIntersecting) {

                    if (!notsignedIn && !hasBeenViewed && !props.memes.includes(postId)) {
                        // Mark the meme as viewed and update the states
                        setHasBeenViewed(true);
                        props.setViews(prevViews => [...prevViews, postId]);

                        // Update the state with the new meme ID
                        props.setMemes(prevMemes => {
                            const updatedMemes = [...prevMemes];
                            if (!updatedMemes.includes(postId)) {
                                updatedMemes.push(postId);
                            }
                            return updatedMemes;
                        });
                        const currentPosition = props.latestviewedMemePosition + 1; // Increment position

                        if (!props.isTagView) {
                            // Check if currentPosition is a multiple of 20
                            if ((currentPosition % 20 === 0 || currentPosition % 33 === 0) && currentPosition > -1) {
                                markMemesAsViewed(currentPosition);
                                // console.log("position is a multiple of 20: ", currentPosition);
                            }

                            props.setFromPosition(currentPosition); // Update fromPosition with currentPosition
                            props.setLatestviewedMemePosition(currentPosition);
                            // console.log("Updated positions: fromPosition:", currentPosition);
                        }
                        if (props.memes.length >= 18) {
                            try {
                                await props.batchUpdate();
                            } catch (error) {
                                console.error("Error during batch update:", error);
                            }
                        }
                    }
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, []);


    const markMemesAsViewed = async (fromPosition) => {
        try {
            const response = await fetch(`${config.apiHost}/api/v1/posts/viewed?userId=${localStorage.getItem("memerId")}&position=${fromPosition}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("oauth")}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to mark memes as viewed');
            }
        } catch (error) {
            console.error('Error marking memes as viewed:', error);
        }
    };

    return (
        <div ref={targetRef}>
            <div>
                {showOverlay && <OverlayMessage message="You must be signed in to perform these actions:" onClose={handleCloseOverlay} />}

            </div>

            {!imageLoaded && (
                <Skeleton
                    // animation="wave"
                    variant="circular"
                    width={isSmallScreen ? "300px" : "400px"}
                    height={isSmallScreen ? "300px" : "400px"}
                    style={{ borderRadius: "12px", margin: "auto", backgroundColor: "#151515" }}
                />
            )}
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "0.8px solid grey",
                marginTop: isSmallScreen ? "10%" : "3%",
                textAlign: "center"
            }}>
                <Card sx={{ width: isSmallScreen ? "330px" : "430px", backgroundColor: "transparent", color: "white" }}>
                    <CardHeader
                        avatar={
                            <Avatar src={props.avatar} sx={{ bgcolor: red[500], width: "50px", height: "50px" }} aria-label="recipe">
                                {props.name}
                            </Avatar>
                        }
                        action={
                            !notsignedIn && memeId === senderId && (
                                <div>
                                    <IconButton aria-label="settings" style={{ color: "grey" }} onClick={handleMenuClick}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        style={{ marginLeft: "-7%" }}
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem style={{}} onClick={handleDelete}>Delete Post</MenuItem>
                                        {/* Add more MenuItems here as needed */}
                                    </Menu>
                                </div>
                            )
                        }
                        title={
                            <div>
                                <Box display="block">
                                    <Typography
                                        style={{ color: "#B1B2B3", textAlign: "left", display: "flex", gap: "15px" }}
                                        variant="h6"
                                    >
                                        {props.name}
                                        {props.isVerified && (
                                            <Badge color="secondary" overlap="circular" badgeContent={0}>
                                                <VerifiedIcon fontSize="medium" sx={{
                                                    color: 'gold'
                                                }} />
                                            </Badge>
                                        )}
                                    </Typography>
                                    <Typography
                                        style={{ color: "grey" }}
                                        variant="body2"
                                        color="textSecondary"
                                        textAlign="left"
                                    >
                                        {"Meme Posted on " + getDateOnly(props.time)}
                                    </Typography>
                                </Box>
                            </div>
                        }
                    />
                    <div>
                        <p style={{ color: "whitesmoke" }} >{props.description}</p>
                    </div>

                    <CardMedia
                        component="img"
                        style={{
                            maxWidth: "300px", // Limits the width
                            width: "100%",  // Ensures the image scales proportionally within the maxWidth
                            height: "auto",  // Maintains the aspect ratio
                            borderRadius: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "auto",
                        }}
                        image={props.image}
                        alt="funny kenyan memes"
                        onLoad={() => {
                            setImageLoaded(true);
                            updateOnLoad();
                        }}
                        loading="lazy"
                    />


                    <canvas ref={canvasRef} style={{ display: 'none' }} />
                    <CardActions sx={{ display: "flex", justifyContent: "center", marginLeft: "0%", marginRight: "0%" }}>
                        <Tooltip title="Category">
                            <IconButton sx={{ color: "#2AB6FF" }} aria-label="share">
                                <div style={{ display: "flex", justifyContent: "center", textAlign: "center", gap: "10px" }}>
                                    <FontAwesomeIcon icon={faTags} style={{ color: "#45ADE4" }} />
                                    <Typography>{props.tag.length > 10 ? props.tag.substring(0, 10) + '...' : props.tag}</Typography>
                                </div>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Downloads">
                            <IconButton sx={{ color: "#2AB6FF" }} aria-label="download" onClick={() => downloadOrFail()}>
                                <DownloadIcon />
                                <NumberFormatter value={downloads} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Likes">
                            <IconButton sx={props.likedByMe ? { color: "red" } : { color: "#2AB6FF" }} aria-label="add to favorites" onClick={() => handleLocalUpdate('likes')}>
                                <FavoriteIcon />
                                <NumberFormatter value={likes} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Views">
                            <IconButton sx={{ color: "#2AB6FF" }} aria-label="views">
                                <EqualizerIcon />
                                <NumberFormatter value={views} />
                            </IconButton>
                        </Tooltip>
                    </CardActions>
                </Card>
            </div>
        </div>
    );
}
