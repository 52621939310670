import React from 'react';
import { Container, Typography, Box, Paper, Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';

const TermsOfService = () => {
    const navigate = useNavigate();
    const goHome = () => {
        navigate("/home")
    }
    return (
        <Container maxWidth="md" style={{ backgroundColor: "#000000" }}>
            <div style={{display:"flex",justifyContent:"center",textAlign:"center"}}>
                <ChevronLeftIcon fontSize="large" style={{color:"white"}}/>
                <Button onClick={goHome}>Back Home</Button>
            </div>
            <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }} style={{ backgroundColor: "#272727" }}>
                <Typography variant="h4" component="h1" gutterBottom color={"#51BBFE"}>
                    Terms of Service
                </Typography>

                <Box marginBottom={2}  >
                    <Typography variant="h6" gutterBottom color={"#51BBFE"}>
                        1. No Troll Content
                    </Typography>
                    <Typography color={"#C4CDD5"}>
                        MemesKenya is a community for sharing fun and creative content. We do not tolerate any form of memes trolling or harassment. Please avoid posting any content that could be considered inflammatory or disruptive.
                    </Typography>
                </Box>

                <Box marginBottom={2}  >
                    <Typography variant="h6" gutterBottom color={"#51BBFE"}>
                        2. No Pornographic Content
                    </Typography>
                    <Typography color={"#C4CDD5"}>
                        MemesKenya is a platform for all ages. Posting pornographic or sexually explicit content is strictly prohibited. Violating this rule might result in account and email suspension.
                    </Typography>
                </Box>

                <Box marginBottom={2}  >
                    <Typography variant="h6" gutterBottom color={"#51BBFE"}>
                        3. No Insults or Offensive Language
                    </Typography>
                    <Typography color={"#C4CDD5"}>
                        Please keep the language clean and avoid posting content that includes insults, hate speech, or offensive language. We strive to maintain a positive and welcoming environment for everyone.
                    </Typography>
                </Box>

                <Box marginBottom={2}  >
                    <Typography variant="h6" gutterBottom color={"#51BBFE"}>
                        4. Respect Intellectual Property
                    </Typography>
                    <Typography color={"#C4CDD5"}>
                        We might pull down memes that are disputed by people as theirs and they wish not to be used or be available on this platform.
                    </Typography>
                </Box>

                <Box marginBottom={2}  >
                    <Typography variant="h6" gutterBottom color={"#51BBFE"}>
                        5. No Spam or Unsolicited Advertising
                    </Typography>
                    <Typography color={"#C4CDD5"}>
                        MemesKenya is not a place for advertising or promoting unrelated products and services. Any form of spam or unsolicited advertising will be removed.
                    </Typography>
                </Box>

                <Box marginBottom={2}  >
                    <Typography variant="h6" gutterBottom color={"#51BBFE"}>
                        6. Reporting Violations
                    </Typography>
                    <Typography color={"#C4CDD5"}>
                        If you come across any content that violates these rules, please report it to us. We will review the content and take appropriate action.
                    </Typography>
                </Box>

                <Box marginTop={4}>
                    <Typography variant="body2" color="grey" style={{fontSize:"17px"}}>
                        By using MemesKenya, you agree to abide by these terms and help us keep the community fun and safe for everyone.
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
};

export default TermsOfService;
