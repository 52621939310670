import React from 'react';
import Typography from '@mui/material/Typography';

const NumberFormatter = ({ value }) => {
  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  };

  
  return <Typography color={"#3BE477"} fontSize={"18px"}>{formatNumber(value)}</Typography>
};

export default NumberFormatter;
