import { Box, List, useMediaQuery, useTheme, Avatar, Typography, CardActions, Card, CardContent, Button } from "@mui/material";
import '../styles/Categories.css'
import React, { useRef, useEffect, useState, useCallback } from "react";
import Skeleton from '@mui/material/Skeleton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Post from "./Post";
import { useNavigate } from "react-router-dom";
import config from "../scripts/config";


const Categories = () => {
  const [memes, setMemes] = useState([])
  const [image, setImage] = useState('');
  const [tag, setTag] = useState('General');
  const navigate = useNavigate();
  const [views, setViews] = React.useState([]);
  const [latestviewedMeme, setLatestviewedMeme] = useState(null);
  const [latestviewedMemePosition, setLatestviewedMemePosition] = useState(0);
  const [content, setContent] = useState(<AutoLoadOnScroll tag={tag} />);
  const categories = ['All Memes', 'My Memes', 'General', 'Politics and Current Events', 'Entertainment', 'Science & Technology', 'Education and Learning', 'Humor and Satire', 'Media',
    "History and Nostalgia", 'Health and Wellness', 'Relationships and Personal Life', 'Celebrities and Showbiz', 'Sports and Recreation'
  ]
  const [localLikes, setLocalLikes] = React.useState([]);
  const [localDownloads, setLocalDownloads] = React.useState([]);
  const authToken = localStorage.getItem("oauth");
  const [localUnLikes, setLocalUnLikes] = React.useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const sendUpdate = async (type, list) => {
    const url = `${config.apiHost}/api/v1/posts/batchUpdate${type.charAt(0).toUpperCase() + type.slice(1)}?increment=1&userId=${localStorage.getItem("memerId")}`;
    try {
      // console.log(`Sending update for ${type}`, list); // Log the request details
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(list) // Directly send the list of UUIDs
      });
      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }

      // console.log(`Update successful for ${type}`); // Log successful update
    } catch (error) {
      console.error(`Error updating ${type}:`, error);
    }
  };

  const batchUpdate = async () => {
    if (localLikes.length > 0) {
      await sendUpdate('likes', localLikes);
      setLocalLikes([]);
    }

    if (localDownloads.length > 0) {
      await sendUpdate('downloads', localDownloads);
      setLocalDownloads([]);
    }

    if (localUnLikes.length > 0) {
      await sendUpdate('unlikes', localUnLikes);
      setLocalUnLikes([]);
    }

    if (views.length > 0) {
      await sendUpdate('views', views);
      setViews([]);
    }
    if (latestviewedMemePosition > 0 && latestviewedMemePosition !== null) {
      await markMemesAsViewed();
      setLatestviewedMemePosition(null);
    }
  };

  const markMemesAsViewed = async () => {
    try {
      const response = await fetch(`${config.apiHost}/api/v1/posts/viewed?userId=${localStorage.getItem("memerId")}&position=${latestviewedMemePosition}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to mark memes as viewed');
      }
    } catch (error) {
      console.error('Error marking viewed memes:', error);
    }
  };

  const memoizedBatchUpdate = useCallback(async () => {
    await batchUpdate();
  }, []);

  useEffect(() => {

    const handleBeforeUnload = async () => {
      // Perform the batch update before the page unloads
      await memoizedBatchUpdate();
    };

    const intervalId = setInterval(async () => {
      await memoizedBatchUpdate();
    }, 25 * 60 * 1000); // Every 25 minutes

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [localDownloads, localLikes, memoizedBatchUpdate]);

  useEffect(() => {

    setTag('All Memes');

  }, []);

  const handleClick = (tagName) => {
    setTag(tagName);
    setContent(<AutoLoadOnScroll tag={tagName} />);
  };

  const goHome = () => {
    navigate('/home');
  };


  function AutoLoadOnScroll({ tag }) {
    const targetRef = useRef(null);
    const [loadedPosts, setLoadedPosts] = useState([]);
    // const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [likedPostIdsByMe, setLikedPostIdsByMe] = useState([]);
    const [memePositions, setMemePositions] = useState([]);
    const [fromPosition, setFromPosition] = useState(0);
    const [empty, setEmpty] = useState(false);
    const memerId = localStorage.getItem("memerId");
    let page = 0;
    let size = 20;

    const fetchPosts = async () => {
      try {
        const encodedTagName = encodeURIComponent(tag);
        let apiUrl = `${config.apiHost}/api/v1/posts/getPostsByTag?userId=${memerId}&page=${page}&size=${size}&tag=${encodedTagName}`;
        if (tag === "My Memes") {
          apiUrl = `${config.apiHost}/api/v1/posts/getMyPosts?userId=${memerId}&page=${page}&size=${20}`
        }
        const headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Authorization', `Bearer ${localStorage.getItem("oauth")}`);

        const posts = await fetch(apiUrl, {
          method: 'GET',
          headers: headers,
        });

        if (!posts.ok) {
          throw new Error('Network response was not ok');
        } else {
          setEmpty(false)
          page = page +1;
          // console.log(" Response ok", posts.status);
        }

        const apiData = await posts.json();
        // console.log(apiData)

        if (apiData.lastPage || apiData.empty ||  apiData.mediaPosts.content.length === 0) {
          page = page - 1;
          setEmpty(true)
          // console.log("Reached the last page.");
          return;
        }

        const newLikedPostIdsByMe = apiData.likedPostDTOs.content.map((post) => post.postId);
        setLikedPostIdsByMe((prevIds) => [...prevIds, ...newLikedPostIdsByMe]);

        setLoadedPosts((prevLoadedPosts) => [
          ...prevLoadedPosts,
          ...apiData.mediaPosts.content,
        ]);

      } catch (error) {
        console.log("Error fetching data ", error);
      }
    };

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
      };

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fetchPosts();
            page = page +1;
          }
        });
      };

      const observer = new IntersectionObserver(callback, options);

      if (targetRef.current) {
        observer.observe(targetRef.current);
      }

      return () => {
        if (targetRef.current) {
          observer.unobserve(targetRef.current);
        }
      };
    }, []);

    const removePost = (postId) => {
      setLoadedPosts(loadedPosts.filter(post => post.postId !== postId));
    };

    const bull = (
      <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
      >
        •
      </Box>
    );

    const move = () => {
      navigate("/home")
    }
    return (
      <div style={{ justifyContent: "center", display: "flex", flexDirection:"column",alignItems: "center", textAlign: "center", width: "100vw", margin: "0" }}>
        {loadedPosts.length > 0 ? (
          loadedPosts.map((item, index) => (

            <div style={{ margin: "0" }} key={index}>
              <Post
                postId={item.postId}
                name={item.nickName}
                image={`https://www.memeskenya.com${item.imageUrl}`}
                avatar={item.ownerDP}
                time={item.timePosted}
                likes={item.likeCount}
                tag={item.tag}
                downloads={item.downloads}
                views={item.views}
                localDownloads={localDownloads}
                setLocalDownloads={setLocalDownloads}
                localLikes={localLikes}
                setLatestviewedMeme={setLatestviewedMeme}
                setLatestviewedMemePosition={setLatestviewedMemePosition}
                latestviewedMemePosition={item.position}
                setLocalLikes={setLocalLikes}
                setLocalUnLikes={setLocalUnLikes}
                notsignedIn={false}
                likedByMe={likedPostIdsByMe.includes(item.postId)}
                setLikedPostIdsByMe={setLikedPostIdsByMe}
                batchUpdate={batchUpdate}
                setViews={setViews}
                localViews={views}
                removePost={removePost}
                senderId={item.owner}
                isVerified={item.verified}
                memes={memes}
                setMemes={setMemes}
                isTagView={true}
                description={item.postTitle}
              />
            </div>
          ))
        ) : (
          !empty && (<p>Getting content. Please wait...</p>)
        )}
        {empty ?
          (
            <div style={{
              width: isSmallScreen ? "85vw" : "500px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center", // Center items vertically
              margin: "0 auto", // Center the div itself horizontally
              flexDirection: "column"
              }}>
              <Card sx={{ width: isSmallScreen ? "85vw" : "500px", backgroundColor: "#272727" ,marginTop:"200px" }}>
                <p style={{ fontSize: "25px" }}>MemesKenya</p>
                <CardContent style={{ backgroundColor: "#ECEAE9" }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Hapa sasa . . .
                  </Typography>
                  <Typography variant="h5" component="div">
                    Ume{bull}tu{bull}na{bull}sa
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    adjective
                  </Typography>
                  <Typography variant="body2" style={{ color: "black" }}>
                    This just means that category is currently empty.
                    <br />
                    {`"Ukipenda : There are no memes under this tag"`}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={move} size="small" style={{ fontSize: "16px" }}>Back Home</Button>
                </CardActions>
              </Card>
            </div>
          )
          :
          (
            <div ref={targetRef} style={{ width: isSmallScreen ? "85vw" : "500px", color: "white" }}>
              <Box sx={{ display: "flex" }}>
                <Skeleton variant="circular"
                  sx={{ bgcolor: 'grey.900', margin: "5%" }} >
                  <Avatar />
                </Skeleton>
                <Skeleton variant="rectangular"
                  sx={{
                    bgcolor: 'grey.900',
                    height: "20px",
                    marginTop: "8%", borderRadius: "6px"
                  }} >
                  <Typography sx={{ width: "180px" }}></Typography>
                </Skeleton>
              </Box>
              <Skeleton variant="rectangular"
                style={{ backgroundColor: '#141414', margin: "5%", marginTop: "1%", borderRadius: "6px", width: isSmallScreen ? "85vw" : "500px", height: "199px" }}
              >
              </Skeleton>
            </div>
          )
        }
      </div>
    );
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: "1200px",
      margin: "0 auto",
      width:"100vw"
    }}>

      <div id="topPanelTrending">
        <Box id="trendingTopics">
          <Box sx={{ position: 'absolute', left: '0px' }}>
            <ArrowBackIcon onClick={goHome} style={{ color: "whitesmoke", fontSize: "40px", cursor: "pointer" }} />
          </Box>
          <Typography sx={{ fontSize: "24px", color: "#2AB6FF", margin: '0 auto' }}>
            Trending Topics
          </Typography>
        </Box>
        <Box id="scrollableList" sx={{
          display: 'flex',
          overflowX: 'auto',
          padding: '10px',
          maxWidth: '100%',
            }}>
          <List sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            margin: 0,
            whiteSpace: 'nowrap'
            }}>
            {categories
              .filter(item => item !== "All Memes")
              .map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleClick(item)}
                  style={{ marginLeft: "1%", justifyContent: "center", alignItems: "center", display: "flex" }}
                >
                  <Avatar
                    alt={item}
                    src={require("../visuals/mk.webp")}
                    style={{ width: "45px", height: "45px", marginLeft: "3%", cursor: "pointer", border: "3px solid #353535" }}
                  />
                  <Typography
                    sx={{
                      height: "45px",
                      textAlign: "center",
                      justifyContent: "center",
                      alignContent: "center",
                      backgroundColor: "#353535",
                      marginLeft: "-5%",
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      borderRadius: "12px 12px 12px 12px",
                    }}
                  >
                    {item}
                  </Typography>
                </div>
              ))}

          </List>
        </Box>
      </div>
      <div>
        {content}
      </div>
    </div>
  );
};

export default Categories;