import React, { useEffect, useState } from "react";
import '../styles/LoginPage.css'
import { useNavigate } from "react-router-dom";
import { Avatar, Button, Fade, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import InputIcon from '@mui/icons-material/Input';
import { useDispatch } from "react-redux";
import { userActions } from "../store/personSlice";
import checkJWTValidity from "../scripts/CheckJWTExpiry";
import OverlayMessage from "../scripts/OverlayMessage";
import config from "../scripts/config";

function CheckTokenValidity() {
    const navigate = useNavigate();

    useEffect(() => {
        const oauth = localStorage.getItem("oauth");
        if (checkJWTValidity(oauth)) {
            navigate("/home");
        }
    }, []);
}

export const InitializeSession = (data, dispatch) => {
    try {
        const user = {
            name: data.nickName,
            memerId: data.userId,
            authToken: data.token,
            image: data.imageUrl,
            email: data.email,
            temporaryUsername: data.username,
            expiredPassword: data.password
        };

        localStorage.setItem("oauth", data.token);
        localStorage.setItem("memerId", data.userId);
        localStorage.setItem("avatar", `https://memeskenya.com${data.imageUrl}`);
        dispatch(userActions.setDetails(user));
    } catch (error) {
        console.error('Error initializing session:', error);
    }
};

export default function Login() {
    const dispatch = useDispatch();
    CheckTokenValidity();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [passWord, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [showOverlay, setShowOverlay] = useState(false);

    const handleSetUsername = (event) => {
        if (event.target.value !== "") {
            const value = event.target.value;
            // Remove spaces from the input value
            const noSpacesValue = value.replace(/\s/g, '');
            setUsername(noSpacesValue);
            setShowError(false);
        }
    };

    const handleSetPassword = (event) => {
        if (event.target.value !== "") {
            const value = event.target.value;
            // Remove spaces from the input value
            const noSpacesValue = value.replace(/\s/g, '');
            setPassword(noSpacesValue);
            setShowError(false);
        }
    };

    const handleSubmit = async (e) => {
        if (username === '' || passWord === '') {
            return;
        }
        setShowOverlay(true);
        e.preventDefault();

        const loginPayload = {
            username: username,
            password: passWord
        }

        const phoneApiUrl = `${config.apiHost}/api/v1/auth/authenticate`;
        try {
            const response = await fetch(phoneApiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginPayload)
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Login successful');
                localStorage.setItem("loaderShown", "false");
                InitializeSession(data, dispatch);
                navigate('/home');
                setShowOverlay(false);
            } else {
                const errorData = await response.json();
                console.error('Registration failed:', errorData);
                setErrorMessage(errorData.error || 'An error occurred during registration');
                setShowError(true);
                setShowOverlay(false);
              
            }
        } catch (error) {
            console.error('Error:', error);
            setShowOverlay(false);
        }
    };

    const navigate = useNavigate();

    const handleSignUp = () => {
        navigate('/sign-up');
    };

    const handleResetInitiation = () => {
        navigate('/initiate-reset');
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Fade in timeout={{ enter: 3000 }}>
            <div className="login-container">
                {showOverlay && (
                    <div>
                        <OverlayMessage message={"Please wait a second as we verify your credentials . . ."} />
                    </div>
                )}
                <div className="text-center">
                    <Typography style={{ fontSize: "30px" }}>Sign  <span style={{ fontSize: "px" }}>In</span></Typography>
                </div>
                <div className="avatar-container">
                    <Avatar alt="Laugh" src={require('../visuals/mk.webp')} sx={{ width: "100px", height: "100px", border: "5px solid white" }} />
                </div>
                <div id="intro">
                    <Typography style={{ marginLeft: "0%", fontSize: "22px" }}>Browse </Typography>
                    &bull;

                    <Typography style={{ marginLeft: "0%", color: "#3C97C7", fontSize: "22px" }}>Post</Typography>
                    &bull;

                    <Typography style={{ marginLeft: "0%", fontSize: "22px" }}>Share</Typography>
                    &bull;
                    <Typography style={{ marginLeft: "0%", color: "#3C97C7", fontSize: "22px" }}>Download </Typography>
                </div>
                <div className="form-container">
                    <p style={{ fontSize: "20px", textAlign: 'left' }}>Username</p>
                    <input autoComplete="off"  type="email" onChange={handleSetUsername} style={{
                        fontSize: "20px", color: "white", textAlign: "center", backgroundColor: "transparent", height: "35px", width: isSmallScreen ? "70vw" : "30vw", maxWidth: "600px", marginBottom: "0px", border: "1px solid #3C97C7"
                    }} />
                    <p style={{ fontSize: "20px", textAlign: 'left' }}>Password</p>
                    <input autoComplete="off" type="password" onChange={handleSetPassword} style={{
                        fontSize: "20px", color: "white", textAlign: "center", backgroundColor: "transparent", height: "35px", width: isSmallScreen ? "70vw" : "30vw", maxWidth: "600px", marginBottom: "20px", border: "1px solid #3C97C7"
                    }} />
                    <div style={{ textAlign: "left", display: "inline-block" }}>
                        <Button onClick={handleResetInitiation}>Forgot Password?</Button>
                        {showError && (
                            <Typography style={{ color: "#E74032", padding: "5px", fontSize: "20px" }}>{errorMessage}</Typography>
                        )}
                    </div>
                    <Button style={{ border: "#3C97C7 .5px solid", marginBottom: "3%", backgroundColor: "#3C97C7", color: 'white', width: isSmallScreen ? "70vw" : "30vw", maxWidth: "600px", }} onClick={handleSubmit} startIcon={<InputIcon style={{ color: "white" }} />}>Sign In</Button>
                    <Button style={{ border: "#E74032 .5px solid", marginBottom: "3%", backgroundColor: "#E74032", color: 'white', width: isSmallScreen ? "70vw" : "30vw", maxWidth: "600px", }} onClick={handleSignUp} startIcon={<GoogleIcon style={{ color: "white" }} />}>Create Account</Button>
                </div>
            </div>
        </Fade>
    )
}
